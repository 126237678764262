import { useMutation } from "@apollo/client";
import parseErrors from "utils/parseErrors";
import { useWorkspace } from "contexts/Workspace";

import { filterSkipMutationProperties } from "hooks/useQuery/filterProperties";

const useMutationProxy = (
  mutation,
  { onFailure: onFailureGlobal, onSuccess: onSuccessGlobal, ...rest } = {}
) => {
  const { namespace } = useWorkspace() || {};
  const [mutationFunc, ...otherFuncs] = useMutation(mutation, rest);

  const mutationFuncWrap = ({ onSuccess, onFailure, ...props } = {}, ...restProps) => {
    const mutationProps = { ...props, variables: prepareVariables(mutation, props, namespace) };

    return mutationFunc(mutationProps, ...(restProps || []))
      .then((response) => {
        handleSuccessRespone(response, { onFailureGlobal, onFailure, onSuccessGlobal, onSuccess });
      })
      .catch((error) => {
        handleFailureError(error, { onFailureGlobal, onFailure });
      });
  };

  return [mutationFuncWrap, ...otherFuncs];
};

const prepareVariables = (mutation, props, namespace) => ({
  ...props.variables,
  input: filterSkipMutationProperties(mutation, { ...props.variables.input, namespace }),
});

const handleSuccessRespone = (
  response,
  { onFailureGlobal, onFailure, onSuccessGlobal, onSuccess }
) => {
  const { data } = response;
  const responseData = Object.values(data)[0];

  if (responseData.errors) {
    const parsedErrors = parseErrors(responseData.errors);
    onFailureGlobal && onFailureGlobal(parsedErrors);
    onFailure && onFailure(parsedErrors);
  } else {
    onSuccessGlobal && onSuccessGlobal(responseData);
    onSuccess && onSuccess(responseData);
  }

  return response;
};

const handleFailureError = (error, { onFailureGlobal, onFailure }) => {
  if (process.env.REACT_APP_DEVELOPMENT) console.error(error);
  const errors = parseErrors(error.graphQLErrors);

  if (onFailureGlobal) onFailureGlobal(errors);
  if (onFailure) onFailure(errors);

  return error;
};

export default useMutationProxy;
