import React from "react";
import IconButton from "components/IconButton";
import { CaretDownIcon } from "icons";

const CollapseButton = ({ open, ...props }) => (
  <IconButton size="xs" {...props}>
    <CaretDownIcon rotate={open ? -90 : 0} />
  </IconButton>
);

export default CollapseButton;
