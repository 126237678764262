import React from "react";
import Avatar from "components/Avatar";
import Divider from "components/Divider";
import Box from "components/Box";
import { useMenuStyles } from "theme/components/Menu";
import Typography from "components/Typography";
import IconButton from "components/IconButton";
import Popover from "components/Popover";
import MenuItem from "components/Menu/MenuItem";
import NavLink from "components/NavLink";
import { useAuth } from "contexts/Auth";
import clsx from "clsx";
import { useSideBarStyles } from "theme/components/DashboardLayout";

const WorkspaceMenu = ({
  space,
  onOpen,
  onClose,
  className,
  buttonComponent: ButtonComponent = IconButton,
}) => {
  const classes = useMenuStyles();
  const sidebarClasses = useSideBarStyles();
  const { currentProfile, logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    onOpen && onOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose && onClose();
  };

  return (
    <>
      <ButtonComponent
        className={clsx(className, sidebarClasses.profileButton)}
        size="small"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
        space={space}
      >
        <Avatar
          size="sidebar"
          src={currentProfile.avatar?.url}
          form={currentProfile.avatar?.url ? null : "round"}
        />
      </ButtonComponent>

      <Popover
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box style={{ minWidth: "200px" }}>
          <Box className={classes.header}>
            <UserSpaceTitle />
          </Box>

          <Divider variant="light" space={{ my: 1 }} />

          <NavLink to="/:companyId/settings/companies">
            <MenuItem>All companies</MenuItem>
          </NavLink>

          <Divider variant="light" space={{ my: 1 }} />

          <NavLink to="/:companyId/settings/profile">
            <MenuItem>My Profile</MenuItem>
          </NavLink>

          <NavLink to="/:companyId/settings/profile">
            <MenuItem>Account settings</MenuItem>
          </NavLink>

          <NavLink disabled>
            <MenuItem>Notifications</MenuItem>
          </NavLink>

          <Divider variant="light" space={{ my: 1 }} />

          <NavLink to="/:companyId/settings/general">
            <MenuItem>Company settings</MenuItem>
          </NavLink>

          <NavLink to="/:companyId/settings/channels">
            <MenuItem>Channels</MenuItem>
          </NavLink>

          <NavLink disabled>
            <MenuItem>Help & Support</MenuItem>
          </NavLink>

          <Divider variant="light" space={{ my: 1 }} />

          <MenuItem onClick={logout}>Log out all</MenuItem>
        </Box>
      </Popover>
    </>
  );
};

export const UserSpaceTitle = () => {
  const { currentProfile } = useAuth();

  return (
    <Box display="flex" alignItems="start">
      <Avatar size="sidebar" space={{ mr: 1 }} src={currentProfile.avatar?.url} />

      <div>
        <Typography color="dark" variant="body1">
          Private space
        </Typography>

        <Typography color="hint" variant="caption">
          {currentProfile.email}
        </Typography>
      </div>
    </Box>
  );
};

export default WorkspaceMenu;
