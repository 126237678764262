import { useMemo } from "react";
import { gql } from "@apollo/client";
import fragments from "graphql/fragments";
import usePaginationQuery from "hooks/useQuery/usePaginationQuery";

import { GlobalSearch } from "./GlobalSearch";

export const GLOBAL_SEARCH = gql`
  query GlobalSearch($input: GlobalSearchInput) {
    globalSearch(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...GlobalSearchFragment
      }
    }
  }

  ${GlobalSearch.fragment}
`;

export const useGlobalSearchQuery = () => {
  const { content = [], ...rest } = usePaginationQuery(GLOBAL_SEARCH, {
    onlySearch: true,
  });

  const searchItems = useMemo(
    () => content.map((item) => new GlobalSearch(item)),
    // eslint-disable-next-line
    [content]
  );

  return { searchItems, ...rest };
};
