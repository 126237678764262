import { delegate, memoize } from "utils/entities";
import { gql } from "@apollo/client";

import { Content } from "models/fragments/Content";

export class NavigationTable {
  constructor(origin) {
    this.origin = origin;

    delegate(this, origin);

    this.cache = {};
    this.memoize = memoize(this.cache);
  }
}

NavigationTable.fragment = gql`
  fragment NavigationTableFragment on NavigationTableViewPayload {
    id
    uid
    name
    description
    avatar {
      ...ContentFragment
    }
    isFavorite
  }

  ${Content.fragment}
`;
