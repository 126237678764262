export const addRecord = ({ entities, metadata, record }) => {
  const nextMetadata = {
    ...metadata,
    totalElements: metadata.totalElements + 1,
  };

  const nextEntities = [record, ...entities];

  return [nextEntities, nextMetadata];
};

export const replaceRecords = (collection, recordsVariables) => {
  const ids = Object.keys(recordsVariables);

  return collection.map((entity) =>
    ids.includes(entity.id) ? { ...entity, ...recordsVariables[entity.id] } : entity
  );
};

export const replaceRecordByIndex = (collection, variables, index) => {
  const result = Array.from(collection);
  const [updated] = result.splice(index, 1);
  result.splice(index, 0, { ...updated, ...variables });

  return result;
};

export const removeRecords = ({ entities, metadata, ids }) => {
  const nextMetadata = {
    ...metadata,
    totalElements: metadata.totalElements - ids.length,
  };

  const nextEntities = entities.filter((entity) => !ids.includes(entity.id));

  return [nextEntities, nextMetadata];
};

export const reorder = (collection, startIndex, endIndex) => {
  const result = Array.from(collection);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const indexMap = (collection, property = "id") => {
  return collection.reduce((result, field, index) => {
    return { ...result, [field[property]]: index };
  }, {});
};

export const collectionToOptions = (collection, { id = "id", name = "name" } = {}) => {
  if (!collection?.length) return [];

  return collection.map((entity) => [entity[id], entity[name] || entity.label || entity.title]);
};

export const objectToOptions = (object) => Object.keys(object).map((key) => [key, object[key]]);

export const optionsToCollection = (options) => {
  if (!options?.length) return [];

  return options.map(([id, name]) => ({ id, name }));
};

export const keyByValue = (values, { key = "id", value = "value" }) =>
  values.reduce((result, field) => {
    result[field[key]] = field[value];

    return result;
  }, {});

export const availableFromFields = (fields, usedFieldsIds, selectedFieldId) =>
  fields.filter((field) =>
    selectedFieldId === field.id ? true : !usedFieldsIds.includes(field.id)
  );
