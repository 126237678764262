const isClassComponent = (component) =>
  typeof component === "function" && !!component?.prototype?.isReactComponent;

const isFunctionComponent = (Component) => typeof Component === "function";

export const isReactComponent = (component) =>
  !!component && (isFunctionComponent(component) || isClassComponent(component));

export const isNumber = (value) => Number(value) === value;

export const isNone = (value) => value === null || value === undefined;

export const isBlank = (value) =>
  isNone(value) || value === "" || (Array.isArray(value) && value.length === 0);

const IMAGE_TYPES = [
  ".webp",
  ".svg",
  ".png",
  ".jpg",
  ".jpeg",
  ".jfif",
  ".pjpeg",
  ".pjp",
  ".gif",
  ".avif",
  ".apng",
];

export const isFileImage = (filename) => {
  return filename && IMAGE_TYPES.includes(filename.slice(filename.lastIndexOf(".")));
};
