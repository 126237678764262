import React, { useState, useContext, createContext } from "react";
import { SnackbarProvider } from "notistack";
import { Snackbar } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import SuccessLabel from "components/Alerts/SuccessAlert";
import ErrorLabel from "components/Alerts/ErrorAlert";
import { toString as errorsToString } from "utils/parseErrors";

const NotificationsContext = createContext(null);

const InitOptions = { open: false, message: "", status: "" };

export const NotificationsProvider = ({ children }) => {
  const [options, setOptions] = useState(InitOptions);

  const handleClose = () => {
    setOptions((o) => ({ ...InitOptions, status: o.status }));
  };

  const notifySuccess = (message) => {
    setOptions({ open: true, message, status: "success" });
  };

  const notifyError = (error) => {
    const errorMessage =
      typeof error === "object" && error.submit ? error.submit : errorsToString(error);

    setOptions({ open: true, message: errorMessage, status: "error" });
  };

  return (
    <>
      <NotificationsContext.Provider value={{ notifySuccess, notifyError }}>
        <SnackbarProvider>{children}</SnackbarProvider>
      </NotificationsContext.Provider>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={options.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Box style={{ minWidth: 300 }} clone>
          {options.status === "error" && (
            <ErrorLabel>
              <Typography variant="body1">{options.message}</Typography>
            </ErrorLabel>
          )}

          {options.status === "success" && (
            <SuccessLabel>
              <Typography variant="body1" color="white">
                {options.message}
              </Typography>
            </SuccessLabel>
          )}
        </Box>
      </Snackbar>
    </>
  );
};

export const useNotification = () => useContext(NotificationsContext);

export default NotificationsContext;
