import React, { useState, useEffect } from "react";
import { Menu as MuiMenu, Fade } from "@mui/material";

export const useDelayOpen = (open, delayOpening = true) => {
  const [contentOpen, setContentOpen] = useState(open);

  useEffect(() => {
    if (open === contentOpen) return;
    const timeOutId = setTimeout(() => setContentOpen(open), 300);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [open]);

  return delayOpening ? contentOpen : open;
};

const Menu = ({ children, open, ...props }) => {
  const delayOpen = useDelayOpen(open);

  return (
    <MuiMenu TransitionComponent={Fade} open={open} {...props}>
      {(open || delayOpen) && children}
    </MuiMenu>
  );
};

export default Menu;
