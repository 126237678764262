import React, { useEffect } from "react";
import Button from "components/Button";
import { useMenuStyles } from "theme/components/Menu";
import Menu from "components/Menu";

const MenuButton = ({
  anchorEl,
  setAnchorEl,
  children,
  onClose,
  onOpen,
  onClick,
  title,
  component: Component = Button,
  PaperProps = {},
  MenuListProps = {},
  ...componentProps
}) => {
  const open = Boolean(anchorEl);
  const classes = useMenuStyles();

  const handleOpen = (event) => {
    onClick && onClick(event);
    setAnchorEl && setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    onClose && onClose(event);
    setAnchorEl && setAnchorEl(null);
  };

  useEffect(() => {
    open && onOpen && onOpen();
  }, [open]);

  return (
    <>
      <Component
        {...componentProps}
        onClick={handleOpen}
        aria-controls="menuButton"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
      >
        {title}
      </Component>

      <Menu
        id="menuButton"
        classes={classes}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={PaperProps}
        MenuListProps={MenuListProps}
      >
        {children}
      </Menu>
    </>
  );
};

export default MenuButton;
