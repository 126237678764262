import "styles/App/GlobalSearch.scss";

export const useGlobalSearchBarStyles = () => ({
  root: "AppGlobalSearchBar-root",
  paper: "AppGlobalSearchBar-paper",
  filters: "AppGlobalSearchBar-filters",
});

export const useGlobalSearchInputStyles = () => ({
  root: "AppGlobalSearchInput-root",
});

export const useGlobalSearchItemStyles = () => ({
  root: "AppGlobalSearchItem-root",
  content: "AppGlobalSearchItem-content",
});
