import React from "react";
import { CircularProgress as MuiCircularProgress } from "@mui/material";
import withClasses from "components/withClasses";

const CircularProgress = ({ innerRef, ...props }) => {
  return <MuiCircularProgress ref={innerRef} {...props} />;
};

export default withClasses(CircularProgress, "MuiCircularProgress", {
  variant: null,
  size: "medium",
});
