import React from "react";
import omit from "lodash/omit";
import pick from "lodash/pick";
import kebabCase from "lodash/kebabCase";
import clsx from "clsx";

const convertObjectToClass = (object) =>
  Object.keys(object).reduce(
    (result, property) => [...result, `${kebabCase(property)}-${object[property]}`],
    []
  );

const displayProps = ["display", "alignItems", "justifyContent"];

const withStyles = (Component) =>
  React.forwardRef((props, ref) => {
    const { sx, className, space = {}, ...rest } = props;

    const spaceClassName = convertObjectToClass(space);

    const displayClassName = convertObjectToClass(pick(rest, displayProps));

    return (
      <Component
        {...omit(rest, displayProps)}
        className={clsx([...spaceClassName, ...displayClassName, className])}
        innerRef={props.innerRef || ref}
      />
    );
  });

export default withStyles;
