import { variantsClassNames } from "theme/utils";

import "styles/Mui/MuiOutlinedInput.scss";

export const useSelectStyles = ({ variant, size = "medium" }) => ({
  root: variantsClassNames("AppSelect-root", { variant, size }),
  icon: "AppSelect-icon",
  option: "AppSelect-option",
  placeholder: "AppSelect-placeholder",
  helperText: "AppSelect-helperText",
  searchValueTags: variantsClassNames("AppSelect-searchValueTags", { size }),
  maskSelectIcon: "AppMaskSelect-icon",
});
