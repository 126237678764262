import React from "react";
import { useAccessManager } from "contexts/AccessManager";
import isEmpty from "lodash/isEmpty";

export const AccessSection = ({ children, ...accessProps }) => {
  const { accessManager } = useAccessManager();
  const { accessRule, accessEntity, can } = accessProps;

  if (Object.prototype.hasOwnProperty.call(accessProps, "can")) {
    return can ? children : <></>;
  }

  if (isEmpty(accessProps)) return children;
  if (accessManager.hasAccess(accessRule, accessEntity)) return children;

  return <></>;
};

export const AccessLoadingSection = ({ children, ...props }) => {
  const { loading } = useAccessManager();

  const accessProps = { loading };

  if (loading) return children(accessProps);

  return <AccessSection {...props}>{children(accessProps)}</AccessSection>;
};

export * as accessRules from "models/abstract/accessRules";
