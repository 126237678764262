import React, { Suspense } from "react";
import SlashScreen from "components/SlashScreen";

import dashboardRoutes from "./dashboardRoutes";
import authRoutes from "./authRoutes";

import { NotFound, AccessDenied, NewIconsKits, TableProTestPage } from "./pages";

const routes = [
  {
    path: "/404",
    element: (
      <Suspense fallback={<SlashScreen />}>
        <NotFound />
      </Suspense>
    ),
  },
  {
    path: "/new-icons-kits",
    element: (
      <Suspense fallback={<SlashScreen />}>
        <NewIconsKits />
      </Suspense>
    ),
  },
  {
    path: "/403",
    element: (
      <Suspense fallback={<SlashScreen />}>
        <AccessDenied />
      </Suspense>
    ),
  },
  {
    path: "/TableProTest",
    element: (
      <Suspense fallback={<SlashScreen />}>
        <TableProTestPage />
      </Suspense>
    ),
  },
  ...authRoutes,
  ...dashboardRoutes,
  {
    path: "*",
    element: (
      <Suspense fallback={<SlashScreen />}>
        <NotFound />
      </Suspense>
    ),
  },
];

export default routes;
