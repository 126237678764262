import { gql } from "@apollo/client";
import { serialize } from "utils/entities";
import { Content } from "models/fragments/Content";
import { ProjectShort } from "./ProjectShort";

export class ProjectFull extends ProjectShort {
  constructor(origin) {
    super(origin);

    serialize(this, { avatar: Content });
  }
}

ProjectFull.fragment = gql`
  fragment ProjectFullFragment on GetProjectPayload {
    ...ProjectShortFragment
    inUse
    description
    defaultPage
    createdDate
    lastModifiedDate
    avatar {
      ...ContentFragment
    }
  }

  ${ProjectShort.fragment}
  ${Content.fragment}
`;
