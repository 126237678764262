import compact from "lodash/compact";
import { TypeNumberIcon } from "icons";

export class FieldType {
  constructor({ type, subtype }) {
    this.type = type;
    this.subtype = subtype;
  }

  get isFormula() {
    return this.type === FORMULA_TYPE;
  }

  get key() {
    return compact([this.type, this.subtype]).join(".");
  }

  get dataTypeLabel() {
    return DATA_TYPE_LABEL[this.key];
  }

  get isFiltrableType() {
    return ![FORMULA_TYPE, AUTO_INCREMENT_TYPE].includes(this.type);
  }

  get isList() {
    return LIST_TYPE === this.type;
  }

  get isEditableType() {
    return ![FORMULA_TYPE, AUTO_INCREMENT_TYPE].includes(this.type);
  }
}

export const NUMBER_TYPE = "NUMBER";
export const AUTO_INCREMENT_TYPE = "AUTO_INCREMENT";
export const FORMULA_TYPE = "FORMULA";
export const CHECKBOX_TYPE = "CHECKBOX";
export const MEDIA_TYPE = "MEDIA";
export const SINGLE_LINE_TEXT_TYPE = "SINGLE_LINE_TEXT";
export const MULTI_LINE_TEXT_TYPE = "MULTI_LINE_TEXT";
export const LIST_TYPE = "LIST";
export const DATETIME_TYPE = "DATETIME";
export const OBJECT_TYPE = "OBJECT";
export const TABLE_CONNECTOR_TYPE = "TABLE_CONNECTOR";

export const INTEGER_SUB_TYPE = "INTEGER";
export const DECIMAL_SUB_TYPE = "DECIMAL";
export const EMAIL_ADDRESS_SUB_TYPE = "EMAIL_ADDRESS";
export const PHONE_NUMBER_SUB_TYPE = "PHONE_NUMBER";
export const HYPERLINK_SUB_TYPE = "HYPERLINK";
export const ASIN_SUB_TYPE = "ASIN";
export const UPC_SUB_TYPE = "UPC";
export const EAN_SUB_TYPE = "EAN";
export const PLAIN_SUB_TYPE = "PLAIN";
export const HTML_SUB_TYPE = "HTML";
export const STATIC_SUB_TYPE = "STATIC";
export const DYNAMIC_SUB_TYPE = "DYNAMIC";
export const DATETIME_SUB_TYPE = "DATETIME";
export const DATE_SUB_TYPE = "DATE";
export const TIME_SUB_TYPE = "TIME";

// Field Type Key

export const OBJECT_FIELD_KEY = OBJECT_TYPE;
export const NUMBER_INTEGER_FIELD_KEY = `${NUMBER_TYPE}.${INTEGER_SUB_TYPE}`;
export const NUMBER_DECIMAL_FIELD_KEY = `${NUMBER_TYPE}.${DECIMAL_SUB_TYPE}`;
export const AUTO_INCREMENT_FIELD_KEY = AUTO_INCREMENT_TYPE;
export const TABLE_CONNECTOR_FIELD_KEY = TABLE_CONNECTOR_TYPE;
export const FORMULA_FIELD_KEY = FORMULA_TYPE;
export const CHECKBOX_FIELD_KEY = CHECKBOX_TYPE;
export const MEDIA_FIELD_KEY = MEDIA_TYPE;
export const SINGLE_LINE_TEXT_PLAIN_FIELD_KEY = `${SINGLE_LINE_TEXT_TYPE}.${PLAIN_SUB_TYPE}`;
export const SINGLE_LINE_TEXT_EMAIL_ADDRESS_FIELD_KEY = `${SINGLE_LINE_TEXT_TYPE}.${EMAIL_ADDRESS_SUB_TYPE}`;
export const SINGLE_LINE_TEXT_HYPERLINK_FIELD_KEY = `${SINGLE_LINE_TEXT_TYPE}.${HYPERLINK_SUB_TYPE}`;
export const SINGLE_LINE_TEXT_PHONE_NUMBER_FIELD_KEY = `${SINGLE_LINE_TEXT_TYPE}.${PHONE_NUMBER_SUB_TYPE}`;
export const SINGLE_LINE_TEXT_ASIN_FIELD_KEY = `${SINGLE_LINE_TEXT_TYPE}.${ASIN_SUB_TYPE}`;
export const SINGLE_LINE_TEXT_UPC_FIELD_KEY = `${SINGLE_LINE_TEXT_TYPE}.${UPC_SUB_TYPE}`;
export const SINGLE_LINE_TEXT_EAN_FIELD_KEY = `${SINGLE_LINE_TEXT_TYPE}.${EAN_SUB_TYPE}`;
export const MULTI_LINE_TEXT_PLAIN_FIELD_KEY = `${MULTI_LINE_TEXT_TYPE}.${PLAIN_SUB_TYPE}`;
export const MULTI_LINE_TEXT_HTML_FIELD_KEY = `${MULTI_LINE_TEXT_TYPE}.${HTML_SUB_TYPE}`;
export const LIST_STATIC_FIELD_KEY = `${LIST_TYPE}.${STATIC_SUB_TYPE}`;
export const LIST_DYNAMIC_FIELD_KEY = `${LIST_TYPE}.${DYNAMIC_SUB_TYPE}`;
export const DATETIME_DATETIME_FIELD_KEY = `${DATETIME_TYPE}.${DATETIME_SUB_TYPE}`;
export const DATETIME_DATE_FIELD_KEY = `${DATETIME_TYPE}.${DATE_SUB_TYPE}`;
export const DATETIME_TIME_FIELD_KEY = `${DATETIME_TYPE}.${TIME_SUB_TYPE}`;

const DATA_TYPE_LABEL = {
  [MULTI_LINE_TEXT_PLAIN_FIELD_KEY]: "Plain text",
  [MULTI_LINE_TEXT_HTML_FIELD_KEY]: "HTML",
  [LIST_STATIC_FIELD_KEY]: "Static",
  [LIST_DYNAMIC_FIELD_KEY]: "Dynamic",
  [MULTI_LINE_TEXT_PLAIN_FIELD_KEY]: "Plain",
  [SINGLE_LINE_TEXT_ASIN_FIELD_KEY]: "ASIN",
  [SINGLE_LINE_TEXT_UPC_FIELD_KEY]: "UPC",
  [SINGLE_LINE_TEXT_EAN_FIELD_KEY]: "EAN",
};

export const fieldTypeIcon = () => TypeNumberIcon;

export const isTimeFormat = (subtype) => [DATETIME_SUB_TYPE, TIME_SUB_TYPE].includes(subtype);
export const isDateFormat = (subtype) => [DATETIME_SUB_TYPE, DATE_SUB_TYPE].includes(subtype);

export const NUMBER_DIGITS_RANGE_TYPE = "DIGITS";
const NUMBER_VALUES_RANGE_TYPE = "VALUES";

export const NUMBER_RANGE_TYPE_OPTIONS = [
  [NUMBER_DIGITS_RANGE_TYPE, "Digits"],
  [NUMBER_VALUES_RANGE_TYPE, "Values"],
];

const MEDIA_FORMAT_PDF_TYPE = "PDF";
const MEDIA_FORMAT_JPG_TYPE = "JPG";
const MEDIA_FORMAT_PNG_TYPE = "PNG";

export const isFileMediaFormat = (format) =>
  [MEDIA_FORMAT_JPG_TYPE, MEDIA_FORMAT_PNG_TYPE].includes(format);

export const MEDIA_FORMAT_OPTIONS = [
  [MEDIA_FORMAT_PDF_TYPE, ".pdf"],
  [MEDIA_FORMAT_JPG_TYPE, ".jpg"],
  [MEDIA_FORMAT_PNG_TYPE, ".png"],
];

export const NUMBER_DOT_SEPARATOR_TYPE = "DOT";
export const NUMBER_COMMA_SEPARATOR_TYPE = "COMMA";
