import React from "react";
import LinearProgress from "components/LinearProgress";
import Box from "components/Box";

import "styles/App/SlashScreen.scss";

const useSlashScreenStyles = () => ({
  root: "AppSlashScreen-root",
});

const SlashScreen = () => {
  const classes = useSlashScreenStyles();

  return (
    <div className={classes.root}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export const RelativeScreen = () => {
  const classes = useSlashScreenStyles();

  return (
    <div className={classes.root} style={{ position: "absolute" }}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export default SlashScreen;
