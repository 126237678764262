export const delegate = (origin, record, exceptKeys = []) => {
  if (!origin || !record) return;

  Object.keys(record).forEach((method) => {
    try {
      if (method !== "origin" && !exceptKeys.includes(method)) {
        origin[method] = record[method];
      }
    } catch (error) {
      console.log(record, method);
      throw error;
    }
  });
};

export const extender = (origin, objectClass, props) => new objectClass({ ...origin, ...props });

export const memoize = (cache) => (key, func) => {
  if (cache[key]) return cache[key];

  cache[key] = func();

  return cache[key];
};

export const serialize = (object, propertiesMap) => {
  Object.keys(propertiesMap).forEach((property) => {
    const ModelClass = propertiesMap[property];

    if (object.origin && object.origin[property]) {
      object[property] = new ModelClass(object.origin[property]);

      if (
        Object.prototype.hasOwnProperty.call(object[property], "id") &&
        !object[`${property}Id`]
      ) {
        object[`${property}Id`] = object[property].id;
      }
    } else {
      object[property] = null;
    }
  });
};

export const serializeCollection = (object, propertiesMap) => {
  Object.keys(propertiesMap).forEach((property) => {
    const ModelClass = propertiesMap[property];

    if (object.origin && object.origin[property]) {
      object[property] = object.origin[property].map((data) => new ModelClass(data));
    } else {
      object[property] = [];
    }
  });
};
