import React from "react";
import Typography from "components/Typography";
import { variantsClassNames } from "theme/utils";
import clsx from "clsx";

import "styles/App/Label.scss";

export const useLabelStyles = ({ color = "grey", size = "medium" }) => ({
  root: variantsClassNames("AppLabel-root", { color, size }),
  button: "AppLabel-root--button",
  externalLinkIcon: "AppLabel-externalLinkIcon",
});

const Label = ({ className, color, size, ...props }) => {
  const classes = useLabelStyles({ color, size });

  return <Typography className={clsx(classes.root, className)} {...props} />;
};

export default Label;
