import flatten from "lodash/flatten";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";

const isEmptyValue = (optionValue) =>
  Array.isArray(optionValue)
    ? isEmpty(optionValue)
    : optionValue === "none" || optionValue === "" || !optionValue;

const useSelect = ({ value, onChange, multiple = false }) => {
  const selectValue = multiple ? compact(flatten([value])) : value || "";

  const setSelectValue = (newValue) => {
    const nextValue = multiple ? compact(flatten([newValue])) : newValue || "";
    onChange && onChange(nextValue);
  };

  const onSelect = (value, checked) => {
    let nextValue;

    if (checked) {
      nextValue = multiple ? selectValue.filter((item) => item !== value) : "";
    } else {
      nextValue = multiple ? [...selectValue, value] : value;
    }

    setSelectValue(nextValue);
  };

  const isNoneSelected = isEmptyValue(selectValue);
  const isSelected = !isEmpty(selectValue) && !isNoneSelected;

  const isSelectedValue = (optionValue) => flatten([selectValue]).indexOf(optionValue) > -1;

  return {
    setSelectValue,
    selectValue,
    onSelect,
    isNoneSelected,
    isSelected,
    isSelectedValue,
  };
};

export default useSelect;
