import "styles/App/SideNavigation.scss";

export const useSideNavigationListStyles = () => ({
  root: "AppSideNavList-root__item",
  nested: "AppSideNavList-root__item-nested",
  cornerIcon: "AppSideNavList-root__item-cornerIcon",
  emptyCornerIcon: "AppSideNavList-root__item-cornerIcon--empty",
  textIcon: "AppSideNavList-root__item-texticon",
  emptyIconButton: "AppSideNavList-root__item-button--empty",
  toggleSidebarButton: "AppSideNavList-root__item-toggleSidebarButton",
  moreButton: "AppSideNavList-root__item-moreButton",
  title: "AppSideNavList-root__item-title",
  searchInput: "AppSideNavList-searchInput",
});
