import { gql } from "@apollo/client";
import { delegate, memoize, serialize, serializeCollection } from "utils/entities";
import keyBy from "lodash/keyBy";
import compact from "lodash/compact";

import { FormField } from "models/abstract/FormField";
import { TableColumn } from "models/fragments/TableColumn";
import { TableAccess } from "./TableAccess";

export class SublistTableAccessLink {
  constructor(origin) {
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin);

    this.mapping = this.origin.mapping || [];

    serialize(this, { componentTable: TableAccess });
    serializeCollection(this, { columns: TableColumn });
  }

  get fields() {
    return this.memoize("fields", () => {
      const columnsMap = keyBy(this.componentTable.columns, "id");
      const mappingColumnsMap = keyBy(this.mapping, "componentColumnId");

      const mappingFields = this.mapping.map((column) => {
        const tableColumn = columnsMap[column.componentColumnId];

        if (!tableColumn) return null;

        return {
          ...tableColumn,
          fieldId: tableColumn.fieldId,
          config: tableColumn.config,
          type: tableColumn.type,
          subtype: tableColumn.subtype,
          lastModifiedDate: this.origin.lastModifiedDate,
          hidden: column.hidden,
          lookup: column.lookup,
          dynamic: column.dynamic,
        };
      });

      const componentFields = this.componentTable.columns.map((tableColumn) => {
        const mappingColumn = mappingColumnsMap[tableColumn.id];

        if (mappingColumn) return null;

        return {
          ...tableColumn,
          fieldId: tableColumn.fieldId,
          type: tableColumn.type,
          subtype: tableColumn.subtype,
          config: tableColumn.config,
          lastModifiedDate: this.origin.lastModifiedDate,
          hidden: true,
          lookup: false,
          dynamic: false,
        };
      });

      return compact([mappingFields, componentFields].flat());
    });
  }

  get additionalColumns() {
    return this.memoize("additionalColumns", () => {
      const columnsValue = this.origin.columns || [];
      const lastModifiedDate = this.origin.lastModifiedDate;

      return columnsValue.map((column) => new TableColumn(column, { lastModifiedDate }));
    });
  }

  get formFields() {
    return this.memoize("formFields", () => {
      const columnsFormFields = this.fields
        .filter((field) => !field.hidden)
        .map(
          (column) =>
            new FormField({
              id: column.id,
              fieldId: column.fieldId,
              name: column.id,
              label: column.name,
              disabled: false,
              icon: null,
              type: column.type,
              subtype: column.subtype,
              editable: false,
              required: column.mandatory,
              config: column.config,
              placeholder: column.placeholder,
            })
        );

      const additionalFormFields = this.additionalColumns.map(
        (column) =>
          new FormField({
            id: column.id,
            fieldId: column.fieldId,
            name: column.id,
            label: column.name,
            disabled: false,
            icon: null,
            type: column.type,
            subtype: column.subtype,
            editable: true,
            required: column.mandatory,
            config: column.config,
            placeholder: column.placeholder,
          })
      );

      return compact([columnsFormFields, additionalFormFields].flat());
    });
  }
}

SublistTableAccessLink.fragment = gql`
  fragment SublistTableAccessLinkFragment on SublistTableAccessLink {
    id
    name
    uid
    componentTable {
      ...TableAccessFragment
    }
    mapping {
      componentColumnId
      hidden
      dynamic
      lookup
    }
    columns {
      ...TableColumnFragment
    }
  }

  ${TableAccess.fragment}
  ${TableColumn.fragment}
`;
