import isObject from "lodash/isObject";

const convertCamelCaseToDashes = (str, prefix = "") => {
  let s = [...str];

  s.forEach((l, i) => {
    if (l === ".") {
      s[i] = "-";
      return;
    }

    if (l === l.toUpperCase() && isNaN(l)) {
      s[i] = "-" + l.toLowerCase();
    }
  });

  return `${prefix ? `${prefix}-` : ""}${s.join("")}`;
};

const convertObjectToCustomPropertiesObject = (obj, prefix = "") => {
  const toReturn = {};

  Object.entries(obj).forEach(([key, value]) => {
    const customPropertyName = convertCamelCaseToDashes(key, prefix);

    if (typeof value === "function") return;

    if (isObject(value)) {
      const flattenedValues = convertObjectToCustomPropertiesObject(value, customPropertyName);
      Object.entries(flattenedValues).forEach(([fCustomPropertyName, fValue]) => {
        toReturn[`${fCustomPropertyName}`] = fValue;
      });
    } else {
      toReturn[`--${customPropertyName}`] = value;
    }
  });

  return toReturn;
};

const convertObjectToCustomPropertiesString = (obj, prefix = "") => {
  let toReturn = "";

  const asObject = convertObjectToCustomPropertiesObject(obj, prefix);

  for (let [key, value] of Object.entries(asObject)) {
    toReturn += `${key}: ${value};
`;
  }

  return toReturn.trim();
};

export default convertObjectToCustomPropertiesString;
