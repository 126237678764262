export const fetchParameter = (name) => {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search.slice(1));

  return params.get(name);
};

export const fetchFile = (fileUrl, { onSuccess, onFailure }) => {
  return fetch(fileUrl)
    .then((response) => response.text())
    .then((content) => {
      onSuccess && onSuccess(content);
      return content;
    })
    .catch((error) => {
      console.error(error);
      onFailure && onFailure(error);
    });
};

export const stubRequest = (params, { onSuccess }) => {
  setTimeout(function () {
    onSuccess && onSuccess(true);
  }, 1000);
};

export const downloadContent = (filename, content) => {
  const blob = new Blob([content]);
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
};

export const currentUrl = (path) => window.location.protocol + "//" + window.location.host + path;
