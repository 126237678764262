import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import { useGlobalSearchItemStyles } from "theme/components/GlobalSearch";
import MenuItem from "components/Menu/MenuItem";
import isEmpty from "lodash/isEmpty";
import { TableViewIcon } from "icons";
import { Skeleton } from "@mui/material";

const ListItemLoading = () => <Skeleton height="25px" width="100%" />;

const SearchItem = ({ item, onSelect }) => {
  const classes = useGlobalSearchItemStyles();
  const SearchIcon = TableViewIcon;

  if (item.loading) {
    return (
      <MenuItem
        component={ListItemLoading}
        icon={SearchIcon}
        className={classes.root}
        tickIcon={false}
      />
    );
  }

  const handleSelect = () => onSelect(item);

  return (
    <MenuItem icon={SearchIcon} className={classes.root} tickIcon={false} onClick={handleSelect}>
      <Box className={classes.content}>
        <Typography color="dark">{item.title}</Typography>

        {!isEmpty(item.description) && (
          <>
            <Typography color="hint" space={{ mr: 0.5, ml: 0.5 }}>
              —
            </Typography>

            <Typography color="hint" style={{ whiteSpace: "normal" }}>
              {item.description}
            </Typography>
          </>
        )}
      </Box>
    </MenuItem>
  );
};

export default SearchItem;
