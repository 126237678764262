import * as React from "react";
import { NavLink as BaseNavLink, useParams, generatePath } from "react-router-dom";

const NavLink = React.forwardRef(
  ({ disabled, activeClassName, activeStyle, exact, ...props }, ref) => {
    const params = useParams();

    return (
      <BaseNavLink
        ref={ref}
        {...props}
        disabled={disabled}
        to={props.to && !disabled ? generatePath(props.to, params) : null}
        className={({ isActive }) =>
          [props.className, isActive ? activeClassName : null].filter(Boolean).join(" ")
        }
        style={({ isActive }) => ({
          ...props.style,
          ...(isActive ? activeStyle : null),
        })}
        end={exact}
      />
    );
  }
);

export default NavLink;
