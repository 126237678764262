import React from "react";
import Button from "components/Button";
import Typography from "components/Typography";
import clsx from "clsx";
import { ArrowUpRightIcon, HyperLink2Icon } from "icons";

import { useLabelStyles } from "./Label";

const LabelButton = ({
  className,
  children,
  externalLinkIcon = false,
  leftIcon,
  rightIcon,
  color,
  size,
  typographyVariant,
  ...props
}) => {
  const classes = useLabelStyles({ size, color });

  return (
    <Button className={clsx(classes.root, classes.button, className)} {...props}>
      <Typography
        variant={typographyVariant}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        style={{ display: "flex" }}
        component="div"
      >
        {children}
      </Typography>

      {externalLinkIcon && (
        <div className={classes.externalLinkIcon}>
          <ArrowUpRightIcon />
        </div>
      )}
    </Button>
  );
};

export const ExternalLink = (props) => (
  <LabelButton externalLinkIcon {...props} leftIcon={HyperLink2Icon} />
);

export default LabelButton;
