import React from "react";
import { MenuItem as MenuItemMui, Skeleton } from "@mui/material";
import Box from "components/Box";
import Typography from "components/Typography";
import { useMenuItemStyles } from "theme/components/Menu";
import { TickIcon } from "icons";
import clsx from "clsx";
import Checkbox from "components/Inputs/Checkbox";
import withStyles from "components/withStyles";

const MenuItem = ({
  ellipses = true,
  disableRipple = false,
  children,
  icon: Icon,
  rightIcon: RightIcon,
  tickIcon = true,
  innerRef,
  size = "medium",
  color,
  markVariant,
  selected,
  loading,
  ...props
}) => {
  const classes = useMenuItemStyles({ size, disableRipple, color });

  return (
    <MenuItemMui
      ref={innerRef}
      {...props}
      disableRipple={disableRipple}
      selected={selected}
      classes={classes}
    >
      <Box
        className={ellipses ? "ellipses" : null}
        display="flex"
        alignItems="center"
        style={{ flexGrow: "1" }}
      >
        {Icon && <Icon space={{ mr: 1 }} size="medium" />}

        {markVariant === "checkbox" && (
          <Checkbox fullWidth={false} space={{ mb: 0, mr: 1 }} value={selected} />
        )}

        {markVariant === "crossCheckbox" && (
          <Checkbox fullWidth={false} space={{ mb: 0, mr: 1 }} value={selected} cross />
        )}

        <Typography className={classes.itemContent} component="div" variant="body1">
          {loading ? <Skeleton variant="rect" height="15px" width="100%" /> : children}
        </Typography>
      </Box>

      {tickIcon && (
        <TickIcon size="medium" className={clsx(classes.tickIcon, { visible: selected })} />
      )}

      {RightIcon && <RightIcon space={{ ml: 3 }} size="medium" />}
    </MenuItemMui>
  );
};

export default withStyles(MenuItem);
