import { gql } from "@apollo/client";
import useQuery from "hooks/useQuery";

import { Profile } from "./Profile";

const FETCH_PROFILE = gql`
  query {
    getProfile {
      ...ProfileFragment
    }
  }

  ${Profile.fragment}
`;

export const useGetProfile = () => {
  const { data, loading, ...rest } = useQuery(FETCH_PROFILE);

  const profile = !loading && data?.getProfile ? new Profile(data.getProfile) : null;

  return {
    data,
    profile,
    loading,
    ...rest,
  };
};
