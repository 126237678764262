import { useMemo } from "react";
import { gql } from "@apollo/client";
import usePaginationQuery from "hooks/useQuery/usePaginationQuery";
import { useWorkspace } from "contexts/Workspace";

import { NavigationTable } from "./index";

export const SEARCH_NAVIGATION_TABLES = gql`
  query NavigationTableViews($input: BaseSearchInput) {
    navigationTableViews(input: $input) {
      content {
        ...NavigationTableFragment
      }
    }
  }
  ${NavigationTable.fragment}
`;

export const useSearchNavigationTables = ({ searchInput } = {}) => {
  const { projectId } = useWorkspace();

  const { content = [], ...rest } = usePaginationQuery(SEARCH_NAVIGATION_TABLES, {
    input: searchInput,
    skip: !projectId,
  });

  const navigationTables = useMemo(
    () => content.map((data) => new NavigationTable(data)),
    [content]
  );

  const sortByFavorite = (collection) => collection.sort((a, b) => b.isFavorite - a.isFavorite);

  return { navigationTables, sortByFavorite, content, ...rest };
};
