import { gql } from "@apollo/client";
import { delegate, memoize } from "utils/entities";
import { keyByValue } from "utils/collection";

import { LIST_ITEM_MODEL_TYPE } from "models/types";
import { getListItemTitle } from "models/ListItem/ListItem";

export class GlobalSearch {
  constructor(origin) {
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin);

    const valuesProps = keyByValue(this.origin.values, { key: "columnUid" });
    const valuesIdsProps = keyByValue(this.origin.values, { key: "columnId" });

    delegate(this, { ...this.origin, ...valuesProps, ...valuesIdsProps });
  }

  get modelType() {
    return LIST_ITEM_MODEL_TYPE;
  }

  get title() {
    return this.memoize("title", () => getListItemTitle(this));
  }

  get name() {
    return this.title;
  }
}

GlobalSearch.fragment = gql`
  fragment GlobalSearchFragment on GetItemPayload {
    id
    createdDate
    lastModifiedDate
    version
    uid
    tableId
    values
  }
`;
