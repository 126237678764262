import { gql } from "@apollo/client";
import pick from "lodash/pick";
import { delegate, memoize } from "utils/entities";
import { keyByValue } from "utils/collection";

export const getListItemTitle = (item) =>
  item._name ||
  item._label ||
  item._title ||
  item.values.find((column) => column.columnUid.match(/(title|name|label)/) && column.value)
    ?.value ||
  item.values.find((field) => typeof field.value === "string")?.value ||
  "No title";

export class ListItem {
  constructor(origin, { listStructure, tableLinkId }) {
    this.origin = origin;
    this.listStructure = listStructure;
    this.tableLinkId = tableLinkId;

    this.cache = {};
    this.memoize = memoize(this.cache);

    const valuesProps = keyByValue(this.origin.values, { key: "columnUid" });
    const valuesIdsProps = keyByValue(this.origin.values, { key: "columnId" });

    delegate(this, { ...this.origin, ...valuesProps, ...valuesIdsProps });
  }

  get tableViewId() {
    return this.listStructure.tableViewId;
  }

  get tableStructure() {
    return this.listStructure.tableStructure;
  }

  get typeId() {
    return `ListItem-${this.id}`;
  }

  get title() {
    return this.memoize("title", () => getListItemTitle(this));
  }

  get name() {
    return this.title;
  }

  get valuesMap() {
    return this.memoize("valuesMap", () => keyByValue(this.values, { key: "columnId" }));
  }

  get tableValues() {
    return {
      ...pick(this, ["id", "createdDate", "lastModifiedDate"]),
      ...this.valuesMap,
    };
  }

  get formValues() {
    return this.values.reduce(
      (result, fieldValue) => ({ ...result, [fieldValue.columnId]: fieldValue.value }),
      {}
    );
  }

  get columns() {
    return this.memoize("columns", () => {
      return this.listStructure.columns.map((field) => ({
        ...field,
        value: this.valuesMap[field.uid],
      }));
    });
  }

  get columnsCount() {
    return this.memoize("columnsCount", () => {
      return this.fields.length;
    });
  }

  get sku() {
    return this.memoize("sku", () => {
      const field = this.values.find(
        (column) =>
          (column.columnUid.match(/item_sku/) || column.columnUid.match(/sku/)) && column.value
      );

      return field?.value;
    });
  }
}

ListItem.fragment = gql`
  fragment ListItemFragment on GetItemPayload {
    id
    createdDate
    lastModifiedDate
    version
    uid
    allowComments
    historyVersion
    values
    tableId
  }
`;

export class ListItemByTableColumn extends ListItem {}

ListItemByTableColumn.fragment = gql`
  fragment ListItemByTableColumnFragment on GetItemByTableColumnPayload {
    id
    createdDate
    lastModifiedDate
    version
    uid
    allowComments
    historyVersion
    values
    tableId
  }
`;
