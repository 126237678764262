const useDataFilter = ({ filter, setFilter }) => {
  const removeFilterByFieldName = (filterIds) => {
    setFilter((prev) => prev.filter((filterItem) => !filterIds.includes(filterItem.field)));
  };

  const cleanFilter = () => {
    setFilter([]);
  };

  const applyFilter = filter.filter((item) => item.value && item.operation);

  return {
    filter,
    applyFilter,
    setFilter,
    cleanFilter,
    removeFilterByFieldName,
  };
};

export default useDataFilter;
