import React from "react";
import { Tooltip as MuiTooltip } from "@mui/material";
import withClasses from "components/withClasses";

const Tooltip = ({ innerRef, placement = "bottom-start", ...props }) => {
  return <MuiTooltip ref={innerRef} placement={placement} {...props} />;
};

export const TextTooltip = ({ isRender = true, children }) => {
  if (!isRender) return children;

  return <Tooltip title={children.props.children}>{children}</Tooltip>;
};

export default withClasses(Tooltip, "MuiTooltip", {});
