import React from "react";
import { Button as MuiButton } from "@mui/material";
import Box from "components/Box";
import withClasses from "components/withClasses";
import { ArrowDownIcon } from "icons";
import CircularProgress from "components/CircularProgress";

const VARIANT_PROPS = {
  hint: {
    disableRipple: true,
  },
};

const Button = ({
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  children,
  variantProps,
  innerRef,
  fullWidth,
  circleLoading = false,
  iconSize,
  ...rest
}) => {
  const { size, variant } = variantProps;
  const additionalProps = variant ? VARIANT_PROPS[variant] : {};
  const style = { ...rest.style, ...(fullWidth ? { width: "100%" } : {}) };

  return (
    <MuiButton ref={innerRef} size={size} {...rest} {...additionalProps} style={style}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {circleLoading ? (
          <CircularProgress size={iconSize} className="leftIcon" />
        ) : (
          LeftIcon && <LeftIcon size={iconSize} className="leftIcon" />
        )}

        {children}

        {variant === "select" && !RightIcon ? (
          <ArrowDownIcon className="rightIcon" />
        ) : (
          RightIcon && <RightIcon size={iconSize} className="rightIcon" />
        )}
      </Box>
    </MuiButton>
  );
};

export default withClasses(Button, "MuiButton", { variant: null, size: "medium" });
