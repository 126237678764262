const APP_KEY = "ULTIMATE_APP";

export const Storage = {
  set: (key, value) => {
    return localStorage.setItem(`${APP_KEY}${key}`, JSON.stringify(value));
  },
  get: (key) => {
    const value = localStorage.getItem(`${APP_KEY}${key}`);

    try {
      return JSON.parse(value) || "";
    } catch {
      return "";
    }
  },
};
