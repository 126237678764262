import React, { useRef, useEffect, useState } from "react";
import withClasses from "components/withClasses";
import { Typography as MuiTypography } from "@mui/material";
import Label from "components/Label";
import Box from "components/Box";
import isEmpty from "lodash/isEmpty";
import { isNumber } from "utils/detect";
import { iconSpacing } from "theme/common";
import Skeleton from "@mui/material/Skeleton";
import { TextTooltip } from "components/Tooltip";

const TypographyComponent = ({
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  children,
  label,
  labelHeight,
  labelHide,
  loading,
  variantProps = {},
  iconSize = "small",
  innerRef: propsRef,
  ...props
}) => {
  const ref = useRef();
  const innerRef = propsRef || ref;

  const isIcon = LeftIcon || RightIcon;

  if (!isIcon) {
    return (
      <MuiTypography ref={innerRef} {...props}>
        {loading ? (
          <Skeleton style={{ minWidth: 50, width: "30%", color: "transparent" }} variant="rect">
            -
          </Skeleton>
        ) : (
          children
        )}
      </MuiTypography>
    );
  }

  return (
    <MuiTypography ref={innerRef} {...props} component="div">
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        {LeftIcon && (
          <LeftIcon
            style={{ marginRight: iconSpacing }}
            color={variantProps.color}
            size={iconSize}
          />
        )}

        {loading ? (
          <Skeleton style={{ minWidth: 50 }} variant="rect" height="14px" width="30%" />
        ) : (
          children
        )}

        {RightIcon && (
          <RightIcon
            style={{ marginLeft: iconSpacing }}
            color={variantProps.color}
            size={iconSize}
          />
        )}
      </Box>
    </MuiTypography>
  );
};

export const Typography = withClasses(TypographyComponent, "MuiTypography", {
  variant: null,
  color: null,
  weight: null,
  size: null,
  error: null,
});

export const TypographyValue = ({
  loading,
  emptyMessage = "Empty",
  value,
  label,
  labelHeight,
  labelHide,
  ...props
}) => {
  const isBlankValue = isNumber(value) ? false : isEmpty(value);

  return (
    <Typography loading={loading} color={isBlankValue ? "hint" : null} {...props}>
      {isBlankValue ? emptyMessage : value}
    </Typography>
  );
};

export const TypographyBoolean = (props) => (
  <TypographyValue
    {...props}
    value={
      props.value ? (
        <Label size="small" color="success">
          true
        </Label>
      ) : (
        <Label size="small" color="grey">
          false
        </Label>
      )
    }
  />
);

const isEllipsisActive = (element) => element.offsetWidth < element.scrollWidth;

export const TypographyTooltip = ({ innerRef: propsRef, ...props }) => {
  const ref = useRef();
  const innerRef = propsRef || ref;

  const [elipsisActive, setElipsisActive] = useState(false);

  useEffect(() => {
    setElipsisActive(isEllipsisActive(innerRef.current));
  }, []);

  return (
    <TextTooltip isRender={elipsisActive}>
      <TypographyComponent {...props} innerRef={innerRef} noWrap />
    </TextTooltip>
  );
};

export default Typography;
