import React from "react";
import MenuItem from "components/Menu/MenuItem";
import Box from "components/Box";
import { GroupedVirtuoso, Virtuoso } from "react-virtuoso";
import Typography from "components/Typography";
import isEmpty from "lodash/isEmpty";
import { useSearchMenu } from "theme/components/SearchMenu";

const RenderOptions = ({
  ellipses = true,
  fullHeight = false,
  markVariant,
  menuLabel,
  virtualized,
  maxHeight = 180,
  group,
  disabled,
  groupedOptions,
  labelComponent,
  totalCount,
  handleLoadMore,
  getOptionProperties,
  noOptionsComponent: NoOptionsComponent,
  menuItemProps = {},
}) => {
  const menuClasses = useSearchMenu();

  const renderGroups = group
    ? groupedOptions
    : [
        {
          group: null,
          index: 0,
          key: 0,
          options: groupedOptions,
        },
      ];

  const groupCounts = renderGroups.map((group) => group.options.length);
  const flatOptions = renderGroups.flatMap((group) => group.options);

  const renderHeight = fullHeight ? "100%" : Math.min(flatOptions.length * 40, maxHeight); // + title;

  const noOptions = isEmpty(groupedOptions) || renderGroups.every((group) => group.options === 0);

  if (noOptions) {
    if (NoOptionsComponent) return <NoOptionsComponent space={{ ml: 1, my: 1 }} />;

    return (
      <Typography space={{ ml: 1, my: 1 }} color="grey">
        No options
      </Typography>
    );
  }

  // TODO: Temp fix of issue when totalCount is less than 0. Reproduces with invite list
  if (virtualized && totalCount < 0) {
    return (
      <>
        {menuLabel && (
          <Typography space={{ p: 1 }} component="div" color="grey" variant="caption">
            {menuLabel}
          </Typography>
        )}
      </>
    );
  }

  return (
    <>
      {menuLabel && (
        <Typography space={{ p: 1 }} component="div" color="grey" variant="caption">
          {menuLabel}
        </Typography>
      )}

      {virtualized ? (
        <VirtualizedList
          style={{ height: renderHeight }}
          overscan={100}
          group={group}
          data={flatOptions}
          totalCount={totalCount}
          endReached={handleLoadMore}
          groupCounts={groupCounts}
          groupContent={(groupIndex) => (
            <CollapseMenuItem
              label={renderGroups[groupIndex].group}
              className={menuClasses.groupTitle}
              menuLabel={menuLabel}
            />
          )}
          itemContent={(index) => {
            const option = flatOptions[index];

            if (!option) return <></>;

            return (
              <ListItem
                ellipses={ellipses}
                index={index}
                option={option}
                disabled={disabled}
                markVariant={markVariant}
                labelComponent={labelComponent}
                {...menuItemProps}
                {...getOptionProperties({ option, index })}
              />
            );
          }}
        />
      ) : (
        <Box fullHeightScroll style={{ height: "auto", maxHeight }}>
          {renderGroups.map((groupItem, groupIndex) => (
            <div key={`${groupItem.group}-${groupIndex}`}>
              {!isEmpty(groupItem.group) && (
                <CollapseMenuItem
                  component="li"
                  label={groupItem.group}
                  className={menuClasses.groupTitle}
                  menuLabel={menuLabel}
                />
              )}

              {groupItem.options.map((option, index) => (
                <ListItem
                  key={`${option.id}-${index}`}
                  ellipses={ellipses}
                  index={index}
                  option={option}
                  disabled={disabled}
                  markVariant={markVariant}
                  labelComponent={labelComponent}
                  {...menuItemProps}
                  {...getOptionProperties({ option, index })}
                />
              ))}
            </div>
          ))}
        </Box>
      )}
    </>
  );
};

export const VirtualizedList = ({
  style,
  overscan,
  groupContent,
  groupCounts,
  group,
  itemContent,
  data,
  totalCount,
  endReached,
}) => {
  if (!group) {
    return (
      <Virtuoso
        style={style}
        overscan={overscan}
        data={data}
        itemContent={itemContent}
        totalCount={totalCount}
        endReached={endReached}
      />
    );
  }

  return (
    <GroupedVirtuoso
      style={style}
      overscan={overscan}
      groupCounts={groupCounts}
      groupContent={groupContent}
      itemContent={itemContent}
      totalCount={totalCount}
      endReached={endReached}
    />
  );
};

export const CollapseMenuItem = ({ label, menuLabel, className, component }) => {
  const title = !label || label === "null" ? menuLabel || "Select option" : label;

  return (
    <Box component={component} className={className}>
      <Typography variant="caption" color="grey">
        {title}
      </Typography>
    </Box>
  );
};

const ListItem = ({
  index,
  ellipses,
  option,
  disabled,
  markVariant,
  labelComponent: LabelComponent,
  label,
  value,
  ...props
}) => {
  return (
    <MenuItem
      ellipses={ellipses}
      markVariant={markVariant}
      loading={option.stubLoading}
      tickIcon={markVariant === "tick"}
      disabled={disabled}
      {...props}
    >
      {LabelComponent ? (
        <LabelComponent option={option} value={value} label={label} entity={label} />
      ) : (
        label
      )}
    </MenuItem>
  );
};

export default RenderOptions;
