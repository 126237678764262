import React, { useState } from "react";

const LayoutContext = React.createContext(null);

export const storeOpenedSidebar = (opened) => {
  window.localStorage.setItem("openedSidebar", opened ? "true" : "");
};

export const LayoutProvider = ({ children }) => {
  const defaultOpenedSidebar = Boolean(window.localStorage.getItem("openedSidebar"));
  const [openedSidebar, setOpenedSidebar] = useState(defaultOpenedSidebar);
  const [fixedOpenedSidebar, setFixedOpenedSidebar] = useState(false);
  const [hoveredSidebar, setHoveredSidebar] = useState(false);

  const toggleOpenedSidebar = () => {
    const nextState = !openedSidebar;

    setOpenedSidebar(nextState);
    storeOpenedSidebar(nextState);
  };

  const toggleFixedOpenedSidebar = () => {
    const nextValue = !fixedOpenedSidebar;

    if (nextValue) {
      setFixedOpenedSidebar(nextValue);
    } else {
      setTimeout(() => {
        setFixedOpenedSidebar(nextValue);
      }, 200);
    }
  };

  const value = {
    openedSidebar,
    toggleOpenedSidebar,
    toggleFixedOpenedSidebar,
    fixedOpenedSidebar,
    hoveredSidebar,
    setHoveredSidebar,
  };

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export const useLayout = () => React.useContext(LayoutContext);
