import { variantsClassNames } from "theme/utils";

import "styles/App/SvgIcon.scss";

export const useSvgIconStyles = ({
  variant = "default",
  color = "inherit",
  property = "stroke",
  size = "initial",
}) => ({
  root: variantsClassNames("AppIcon-root", { variant, color, size, property }),
  box: "AppIcon-root__box",
});
