import { useMemo } from "react";
import { gql } from "@apollo/client";
import useQuery from "hooks/useQuery";

export const ADMINS_ROLES = ["PROJECT_OWNER", "PROJECT_ADMIN", "COMPANY_OWNER", "COMPANY_ADMIN"];

const CUSTOM_PERMISSION_TYPES = { VIEW_REMOVE: ["PROJECT_ADMIN"] };

class PermissionsConfig {
  constructor(permissions) {
    this.permissions = permissions;
  }

  get typeRolesMap() {
    return this.permissions.reduce(
      (result, permission) => ({
        ...result,
        [permission.type]: [...(result[permission.type] || []), permission.role],
      }),
      CUSTOM_PERMISSION_TYPES
    );
  }
}

const GET_PERMISSIONS = gql`
  query GetPermissions {
    getPermissions {
      type
      role
    }
  }
`;

export const useGetPermissionsConfig = () => {
  const { payload = [], ...rest } = useQuery(GET_PERMISSIONS);

  const permissionsConfig = useMemo(() => new PermissionsConfig(payload), [payload]);

  return { permissionsConfig, payload, ...rest };
};
