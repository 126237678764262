import { gql } from "@apollo/client";
import { delegate, memoize } from "utils/entities";

export class Timezone {
  constructor(origin) {
    this.origin = origin;

    delegate(this, origin);

    this.cache = {};
    this.memoize = memoize(this.cache);
  }
}

Timezone.fragment = gql`
  fragment TimezoneFragment on Timezone {
    id
    name
    byDefault
  }
`;
