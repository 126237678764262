import React, { useState, useLayoutEffect } from "react";
import { useSearchCompanies, useGetCompany } from "models/Company/queries";
import { useSearchCompanyProjects } from "models/Project/queries";
import SlashScreen from "components/SlashScreen";
import { useRouteMap } from "hooks/useRouteMap";
import { useParams } from "react-router-dom";
import { Storage } from "utils/storage";
import isEmpty from "lodash/isEmpty";
import { Navigate } from "react-router-dom";
import { useAuth } from "contexts/Auth";

const WorkspaceContext = React.createContext(null);

const noEmptyValues = (values) => values.filter((value) => !isEmpty(value));
const noEmptyValue = (values) => noEmptyValues(values)[0];

export const WorkspaceProvider = ({ children }) => {
  const { currentProfile } = useAuth();

  const [workspaceMode, setWorkspaceMode] = useState("COMPANY");
  const { replaceWorkspace, currentRoutePath } = useRouteMap();
  const params = useParams();

  const paramsCompanyId = noEmptyValue([params.companyId, Storage.get("companyId")]);
  const paramsCrojectId = noEmptyValue([params.projectId, Storage.get("projectId")]);

  const { companies, loading: companiesLoading } = useSearchCompanies();

  const currentCompany = companies.find((item) => item.id === paramsCompanyId) || companies[0];
  const isCompanyOwner = currentProfile.id === currentCompany?.ownerId;

  const { company: currentCompanyDetails } = useGetCompany(currentCompany?.id);

  const { projects, loading: projectsLoading } = useSearchCompanyProjects({
    companyId: currentCompany?.id,
  });

  const currentProject = projects.find((item) => item.id === paramsCrojectId) || projects[0];

  const companyId = currentCompany?.id;
  const projectId = currentProject?.id;

  const loading = companiesLoading || projectsLoading;

  const namespace =
    workspaceMode === "PROJECT" ? noEmptyValues([companyId, projectId]).join(",") : companyId;

  const setCompanyId = (companyId) => {
    Storage.set("companyId", companyId);
    replaceWorkspace({ companyId, projectId: null });
  };

  const setProjectId = (projectId) => {
    Storage.set("projectId", projectId);
    replaceWorkspace({ companyId, projectId });
  };

  const isRoot = currentRoutePath === "/:companyId" || currentRoutePath === "/";

  if (isEmpty(companies) && !loading && !window.location.pathname.match(/companies\/create/)) {
    return <Navigate to={`/companies/create`} />;
  }

  if (!currentCompany) {
    return <SlashScreen />;
  }

  if (isRoot && loading) {
    return <SlashScreen />;
  }

  if (
    isCompanyOwner &&
    isEmpty(projects) &&
    !loading &&
    !window.location.pathname.match(/projects\/create/)
  ) {
    return <Navigate to={`/${currentCompany.id}/projects/create`} />;
  }

  if (isRoot && !loading) {
    return <Navigate to={`/${currentCompany.id}/${currentProject?.id}`} />;
  }

  const value = {
    setCompanyId,
    setProjectId,
    companies,
    loading,
    currentCompany: currentCompanyDetails || currentCompany,
    companyId,
    currentProject,
    projectId,
    projects,
    namespace,
    workspaceMode,
    isProjectWorkspace: workspaceMode === "PROJECT",
    setWorkspaceMode,
  };

  return <WorkspaceContext.Provider value={value}>{children}</WorkspaceContext.Provider>;
};

export const useWorkspace = () => React.useContext(WorkspaceContext);

export const CompanyWorkspaceProvider = ({ children }) => {
  const { setWorkspaceMode, companyId } = useWorkspace();
  const { replaceWorkspace } = useRouteMap();
  const params = useParams();

  useLayoutEffect(() => {
    setWorkspaceMode("COMPANY");
  }, []);

  useLayoutEffect(() => {
    Storage.set("companyId", companyId);
    Storage.set("projectId", "");

    if (params.companyId !== companyId) replaceWorkspace({ companyId });
  }, [companyId]);

  return children;
};

export const ProjectWorkspaceProvider = ({ children }) => {
  const { setWorkspaceMode, companyId, projectId } = useWorkspace();
  const { replaceWorkspace } = useRouteMap();

  useLayoutEffect(() => {
    setWorkspaceMode("PROJECT");
  }, []);

  useLayoutEffect(() => {
    Storage.set("companyId", companyId);
    Storage.set("projectId", projectId);

    replaceWorkspace({ companyId, projectId });
  }, [companyId, projectId]);

  return children;
};
