import { parseBackendTimeFormat } from "utils/time";
import { FormField } from "./FormField";

import {
  NUMBER_TYPE,
  CHECKBOX_TYPE,
  SINGLE_LINE_TEXT_TYPE,
  MULTI_LINE_TEXT_TYPE,
  DATETIME_TYPE,
  LIST_TYPE,
  MEDIA_TYPE,
  OBJECT_TYPE,
  AUTO_INCREMENT_TYPE,
  TABLE_CONNECTOR_TYPE,
  FORMULA_TYPE,
} from "./FieldType";

export class FieldTypeConfig {
  constructor({
    type,
    numberConfig,
    singleLineTextConfig,
    multiLineTextConfig,
    datetimeConfig,
    checkboxConfig,
    listConfig,
    mediaConfig,
    autoIncrementConfig,
    objectConfig,
    tableConnectorConfig,
  }) {
    this.type = type;
    this.numberConfig = numberConfig;
    this.singleLineTextConfig = singleLineTextConfig;
    this.multiLineTextConfig = multiLineTextConfig;
    this.datetimeConfig = datetimeConfig;
    this.checkboxConfig = checkboxConfig;
    this.listConfig = listConfig;
    this.mediaConfig = mediaConfig;
    this.autoIncrementConfig = autoIncrementConfig;
    this.objectConfig = objectConfig;
    this.tableConnectorConfig = tableConnectorConfig;
  }

  get subtype() {
    return this.config.subtype;
  }

  get hasInitialValue() {
    return this.formField.hasInitialValue;
  }

  get initialValue() {
    return this.formField.initialValue;
  }

  parseValue(value) {
    return this.formField.parseValue(value);
  }

  formField() {
    return new FormField({ type: this.type, subtype: this.subtype, config: this.config });
  }

  get config() {
    switch (this.type) {
      case NUMBER_TYPE:
        return this.numberConfig;
      case SINGLE_LINE_TEXT_TYPE:
        return this.singleLineTextConfig;
      case MULTI_LINE_TEXT_TYPE:
        return this.multiLineTextConfig;
      case DATETIME_TYPE:
        return {
          ...this.datetimeConfig,
          format: parseBackendTimeFormat(this.datetimeConfig.format),
        };
      case CHECKBOX_TYPE:
        return this.checkboxConfig;
      case LIST_TYPE:
        return this.listConfig;
      case MEDIA_TYPE:
        return this.mediaConfig;
      case AUTO_INCREMENT_TYPE:
        return this.autoIncrementConfig;
      case OBJECT_TYPE:
        return {
          ...this.objectConfig,
          fields: this.objectConfig.fields.map((item) => ({ ...item, fieldId: item.fieldRef.id })),
        };
      case TABLE_CONNECTOR_TYPE:
        return {
          ...this.tableConnectorConfig,
          columns: this.tableConnectorConfig.columns.map((item) => ({
            ...item,
            columnId: item.id,
          })),
        };
      case FORMULA_TYPE:
        return {};
      default:
        throw `Unsupported field type config: ${this.type}`;
    }
  }
}

export const SIMPLE_FIELD_TYPE_CONFIG = `
  numberConfig {
    subtype
    unique
    precision
    initialValue
    separator
    range {
      type
      minValue
      maxValue
    }
  }
  singleLineTextConfig {
    subtype
    unique
    initialValue
    minLength
    maxLength
  }
  multiLineTextConfig {
    subtype
    showEditor
    initialValue
    minLength
    maxLength
  }
  datetimeConfig {
    subtype
    unique
    format
    initialValue
    minValue
    maxValue
  }
  checkboxConfig {
    initialValue
  }
  listConfig {
    subtype
    isMultiple
    tableId
    columnId
    limit
    channelEnum {
      id
      values
    }
    defaultValue {
      fieldItemId
      tableItemId
      value
    }
  }
  mediaConfig {
    formats
    minValue
    maxValue
    showPreview
  }
  autoIncrementConfig {
    digits
    prefix
    suffix
  }
`;

export const FIELD_TYPE_CONFIG = `
  ${SIMPLE_FIELD_TYPE_CONFIG}
  objectConfig {
    fields {
      columnId
      fieldRef {
        id
      }
      mandatory
      search
    }
    fieldsCount
  }
  tableConnectorConfig {
    tableId
    isMultiple
    isWriteable
    writeMode
    columns {
      id
      primary
      lookup
    }
  }
`;
