import React, { useEffect } from "react";
import Button from "components/Button";
import { useMenuStyles } from "theme/components/Menu";
import { useDelayOpen } from "components/Menu";
import { Popover as MuiPopover, Fade } from "@mui/material";

const Popover = ({ children, ContentProps, PaperProps, open, ...props }) => {
  const classes = useMenuStyles();
  const delayOpen = useDelayOpen(open);

  return (
    <MuiPopover
      TransitionComponent={Fade}
      open={open}
      {...props}
      PaperProps={{
        className: classes.paper,
        ...PaperProps,
      }}
      anchorOrigin={{
        vertical: "bottom",
      }}
    >
      {(open || delayOpen) && <div {...ContentProps}>{children}</div>}
    </MuiPopover>
  );
};

export const PopoverButton = ({
  anchorEl,
  setAnchorEl,
  children,
  onClose,
  onOpen,
  onClick,
  title,
  component: Component = Button,
  PaperProps = {},
  ContentProps = {},
  ...componentProps
}) => {
  const open = Boolean(anchorEl);
  const classes = useMenuStyles();

  const handleOpen = (event) => {
    onClick && onClick(event);
    setAnchorEl && setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    onClose && onClose(event);
    setAnchorEl && setAnchorEl(null);
  };

  useEffect(() => {
    open && onOpen && onOpen();
  }, [open]);

  return (
    <>
      <Component
        {...componentProps}
        onClick={handleOpen}
        aria-controls="popoverButton"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
      >
        {title}
      </Component>

      <Popover
        id="popoverButton"
        classes={classes}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={PaperProps}
        ContentProps={ContentProps}
      >
        {children}
      </Popover>
    </>
  );
};

export default Popover;
