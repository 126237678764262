import { variantsClassNames } from "theme/utils";

import "styles/App/Drawer/Drawer.scss";
import "styles/App/Drawer/SubDrawer.scss";

export const useDrawerStyles = ({ size = "medium" } = {}) => ({
  root: variantsClassNames("AppDrawer-root", { size }),
  modal: "AppDrawer-modal",
  hiddenBackdrop: "AppDrawer-hiddenBackdrop",
  paper: "AppDrawer-paper",
  body: "AppDrawer-body",
  bodySimple: "AppDrawer-body--simple",
});

export const useSubDrawerStyles = () => ({
  tabs: "AppSubDrawer-tabs",
  tabPanel: "AppSubDrawer-tabPanel",
  header: "AppSubDrawer-header",
  content: "AppSubDrawer-content",
  titleInput: "AppSubDrawer-titleInput",
});
