import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { Select as MuiSelect, Fade } from "@mui/material";
import FormControl from "components/FormControl";
import { useSelectStyles } from "theme/components/Select";
import { useSearchMenu } from "theme/components/SearchMenu";
import { ArrowDownIcon, CrossBoldIcon } from "icons";
import isEmpty from "lodash/isEmpty";
import Box from "components/Box";
import Typography from "components/Typography";
import IconButton from "components/IconButton";
import JSum from "jsum";

import RenderTags from "../RenderTags";
import SelectOptions from "./SelectOptions";
import useSelect from "./useSelect";

const checksum = (values) => JSum.digest(values, "SHA256", "hex");

export const parseObjectOptions = (object) =>
  Object.keys(object).map((value) => [value, object[value]]);

const SelectSearch = ({
  style = {},
  space,
  disabled,
  fullWidth,
  fullHeight = false,
  options = [],
  menuLabel,
  hint,
  showError,
  error,
  name,
  size = "medium",
  iconSize = "medium",
  placeholder = "Select value",
  variant,
  allowBlank,
  open: initialOpen = null,
  defaultOpen = false,
  onClose,
  onOpen,
  onChange,
  onChangeHook,
  onClean,
  weight,
  cleanable = false,
  multiple = false,
  searchable = false,
  deSelect = null,
  virtualized = false,
  closeOnChange = true,
  defaultValue,
  value,
  markVariant = "tick",
  tagVariant = "text",
  className,
  footer: Footer,
  labelProperty,
  label,
  labelComponent: LabelComponent,
  selectedLabelComponent: SelectedLabelComponent,
  maxHeight,
  totalCount,
  onLoadMore,
  onSearch,
  labelIcon, // TODO
  labelHeight, // TODO
  labelSpace, // TODO
  menuItemProps = {},
  readOnly = false,
  ...props
}) => {
  const menuRef = useRef();
  const selectRef = useRef();
  const [valueOptions, setValueOptions] = useState(options);
  const [open, setOpen] = useState(defaultOpen);

  const classes = useSelectStyles({ size, variant });
  const menuClasses = useSearchMenu();

  const handleChange = (newValue) => {
    onChange && onChange(newValue);
    onChangeHook && onChangeHook(newValue);
  };

  const { setSelectValue, selectValue, onSelect, isNoneSelected, isSelected } = useSelect({
    value,
    onChange: handleChange,
    multiple,
  });

  const handleClean = () => {
    setSelectValue(multiple ? [] : "");
    onClean && onClean();
  };

  const handleOpen = () => {
    onOpen && onOpen();
    setOpen(true);
  };

  const handleClose = (event) => {
    if (menuRef.current?.contains(event.target)) return;

    onClose && onClose();
    setOpen(false);
  };

  const onSelectHook = () => {
    !multiple && closeOnChange && setOpen(false);
  };

  const handleSelect = (value, checked) => () => {
    onSelect(value, checked);
    onSelectHook();
  };

  let IconComponent = (props) => <ArrowDownIcon {...props} size={iconSize} className="rightIcon" />;

  const isCleanableSelected = cleanable && isSelected;

  if (isCleanableSelected) {
    IconComponent = () => (
      <IconButton
        onMouseDown={handleClean}
        variant="circle"
        icon={CrossBoldIcon}
        className={classes.icon}
        style={{ pointerEvents: "all", backgroundColor: "tranparent" }}
      />
    );
  }

  // const placeholderLabel = PLACE_HOLDERS[placeholder] || PLACE_HOLDERS[name] || placeholder;

  const valueOptionsMap = valueOptions.reduce(
    (result, option) => ({ ...result, [option[0]]: option }),
    {}
  );

  const handleRemoveOption = (value) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    handleSelect(value, true)();
  };

  useEffect(() => {
    setValueOptions([
      ...valueOptions,
      ...options.filter((option) => [selectValue].flat().includes(option[0])),
    ]);
  }, [checksum([selectValue, ...options])]);

  useEffect(() => {
    if (value || !defaultValue) return;
    if (value === defaultValue) return;

    handleChange(defaultValue);
  }, [defaultValue, value]);

  return (
    <FormControl
      space={{ mb: 1, ...space }}
      style={style}
      fullWidth={fullWidth}
      size={size}
      variant="outlined"
      hint={hint}
      name={name}
      error={error}
      showError={showError}
    >
      {label && (
        <Box mb={1}>
          <Typography error={`${!!error}`} variant="inputLabel">
            {label}
          </Typography>
        </Box>
      )}

      <MuiSelect
        {...props}
        readOnly={readOnly}
        disabled={disabled}
        ref={selectRef}
        multiple={multiple}
        classes={classes}
        className={clsx(className, {
          [classes.placeholder]: isNoneSelected,
          cleanableSelected: isCleanableSelected,
        })}
        open={initialOpen === null ? open : initialOpen}
        displayEmpty={true}
        onOpen={handleOpen}
        onClose={handleClose}
        data-id={name}
        labelId={`select-label-${name}`}
        id={name}
        value={selectValue}
        error={!!error}
        IconComponent={IconComponent}
        MenuProps={{
          classes: menuClasses,
          MenuListProps: { className: menuClasses.list },
          PaperProps: { className: menuClasses.paper },
          TransitionComponent: Fade,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        renderValue={(value) => (
          <RenderTags
            size={size}
            inputVariant={variant}
            iconSize={iconSize}
            value={value}
            classes={classes}
            emptyPlaceholder={placeholder}
            placeholder={placeholder}
            options={options}
            weight={weight}
            tagVariant={tagVariant}
            labelComponent={SelectedLabelComponent || LabelComponent}
            labelProperty={labelProperty}
            isEmptyOptions={isEmpty(options)}
            getTagProps={({ value }) => ({ onDelete: handleRemoveOption(value) })}
            getOption={({ value }) => valueOptionsMap[value]}
            getOptionLabel={(option) => (option ? option[1] || "" : "")}
          />
        )}
      >
        <SelectOptions
          menuItemProps={menuItemProps}
          fullHeight={fullHeight}
          maxHeight={maxHeight}
          disabled={disabled}
          options={options}
          menuLabel={menuLabel}
          allowBlank={allowBlank}
          onSelect={onSelectHook}
          dataValue={value}
          onChange={handleChange}
          onClean={onClean}
          onClose={() => setOpen(false)}
          multiple={multiple}
          searchable={searchable}
          deSelect={deSelect}
          virtualized={virtualized}
          markVariant={markVariant}
          footer={Footer}
          labelComponent={LabelComponent}
          labelProperty={labelProperty}
          totalCount={totalCount}
          onLoadMore={onLoadMore}
          onSearch={onSearch}
        />
      </MuiSelect>
    </FormControl>
  );
};

export default SelectSearch;
