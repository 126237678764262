import React from "react";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import pick from "lodash/pick";
import merge from "lodash/merge";
import kebabCase from "lodash/kebabCase";
import isNull from "lodash/isNull";
import clsx from "clsx";

const convertObjectToClass = (object, prefix = "") =>
  Object.keys(object).reduce(
    (result, property) => [...result, `${prefix}${kebabCase(property)}-${object[property]}`],
    []
  );

const displayProps = ["display", "alignItems", "justifyContent"];

const withClasses = (Component, classId, variantsDefaultProps = []) =>
  React.forwardRef((props, ref) => {
    const { sx, className, space = {}, ...rest } = props;

    const variantsProps = Object.keys(variantsDefaultProps);

    const componentProps = omit(rest, [...displayProps, ...variantsProps]);

    const compomentVariantsProps = merge(
      omitBy(variantsDefaultProps, isNull),
      omitBy(pick(rest, variantsProps), isNull)
    );

    const variantsClassNames = convertObjectToClass(compomentVariantsProps, `${classId}-style--`);

    const spaceClassNames = convertObjectToClass(space);

    const displayClassNames = convertObjectToClass(pick(rest, displayProps));

    return (
      <Component
        {...componentProps}
        className={clsx([
          ...displayClassNames,
          ...variantsClassNames,
          ...spaceClassNames,
          className,
        ])}
        innerRef={ref}
        variantProps={compomentVariantsProps || {}}
      />
    );
  });

export default withClasses;
