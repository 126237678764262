const PLACE_HOLDERS = {
  email: "e.g. ivanov@gmail.com",
  phone: "e.g. +380631759063",
  workEmail: "e.g. ultimate@gmail.com",
  password: "Enter password",
  confirmPassword: "Confirm password",
  firstName: "e.g. Bernice",
  lastName: "e.g. Mcdonald",
  setValue: "set value",
  selectValue: "select value",
  setDate: "set date",
  setTime: "set time",
  searchByName: "Search by name...",
  name: "Product Catalog",
  uid: "_product_catalog",
  type: "Set type...",
  folderId: "Set Folder...",
  parentFolder: "Set Parent Folder...",
  description: "Add description...",
  emptyOptions: "No Options",
  viewName: 'e.g. "My view", keep it short..',
  mappingName: "e.g. Product mapping, keep it short..",
  fieldName: "e.g. Item ID, keep it short..",
  tableName: "e.g. Product items",
  search: "Search...",
  country: "Set Country",
  currency: "Set Currency",
  selectField: "Select field",
  taskName: "Import items",
  templateName: "Template name",
  channel: "Channel",
  channelApiType: "Channel Api Type",
};

export default PLACE_HOLDERS;
