import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export const useDialog = (value = false) => {
  const [openedDialog, setOpenedDialog] = useState(value);
  const toggleDialog = () => setOpenedDialog((prev) => !prev);
  const openDialog = () => setOpenedDialog(true);
  const closeDialog = () => setOpenedDialog(false);

  return [openedDialog, toggleDialog, closeDialog, openDialog];
};

export const useStepForm = (schema) => {
  const {
    handleSubmit,
    control,
    formState,
    setValue,
    watch,
    setError,
    getValues,
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  return {
    handleSubmit,
    control,
    formState,
    setValue,
    watch,
    getValues,
    clearErrors,
    setError,
    reset,
  };
};

export const useMenuAnchor = (defaultValue) => {
  const [anchorEl, setAnchorEl] = useState(defaultValue);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return { anchorEl, setAnchorEl, open, handleOpen, handleClose };
};

export const useOpener = (defaultValue) => {
  const [open, setOpen] = useState(defaultValue);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  return [open, handleOpen, handleClose, toggleOpen];
};
