import React, { useMemo } from "react";
import "./styles/App.scss";
import "nprogress/nprogress.css";
import { NotificationsProvider } from "./contexts/Notifications";
import { MUI_THEMES, THEMES } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { HelmetProvider } from "react-helmet-async";
import ApolloProvider from "providers/ApolloProvider";
import OsProvider from "providers/OsProvider";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import convertToCss from "theme/convertToCss";
import JSum from "jsum";

import routes from "./routes";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter(routes);

const cache = createCache({
  key: "mui",
  prepend: true,
});

const App = () => {
  const muiTheme = MUI_THEMES.LIGHT;
  const theme = THEMES.LIGHT;

  const cssTheme = useMemo(() => convertToCss(theme), [JSum.digest(theme, "SHA256", "hex")]);

  return (
    <CacheProvider value={cache}>
      <style>{`:root { ${cssTheme} }`}</style>

      <ThemeProvider theme={muiTheme}>
        <OsProvider>
          <NotificationsProvider>
            <ApolloProvider>
              <HelmetProvider>
                <RouterProvider router={router} />
              </HelmetProvider>
            </ApolloProvider>
          </NotificationsProvider>
        </OsProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
