import React from "react";
import { Navigate } from "react-router-dom";
import { AuthProvider } from "contexts/Auth";
import { useAuth } from "contexts/Auth";

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

const GuestGuardWrap = (props) => (
  <AuthProvider>
    <GuestGuard {...props} />
  </AuthProvider>
);

export default GuestGuardWrap;
