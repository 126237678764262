import { gql } from "@apollo/client";
import * as fragment from "./fragments";

export const SIGN_UP_MUTATION = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      success
    }
  }
`;

export const SIGN_IN_MUTATION = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      accessToken
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($input: PasswordResetInput!) {
    resetPassword(input: $input) {
      success
    }
  }
`;

export const SIGN_UP_CONFIRM_MUTATION = gql`
  mutation SignUpConfirm($input: SignUpConfirmInput!) {
    signUpConfirm(input: $input) {
      accessToken
    }
  }
`;

export const RESET_PASSWORD_CONFIRM_MUTATION = gql`
  mutation ResetPasswordConfirm($input: ResetPasswordConfirmInput!) {
    resetPasswordConfirm(input: $input) {
      accessToken
    }
  }
`;

export const UPLOAD_TASK_FILE = gql`
  mutation UploadTaskFile($input: UploadTaskFileInput) {
    uploadTaskFile(input: $input) {
      ${fragment.SOURCE_FILE}
      fileTokenDelimiter
      valid
      headers
      lines
    }
  }
`;
