import { gql } from "@apollo/client";
import { memoize } from "utils/entities";

const ADMINS_ROLES = ["PROJECT_OWNER", "PROJECT_ADMIN", "COMPANY_OWNER", "COMPANY_ADMIN"];

export class PermissionsMap {
  constructor(origin) {
    this.origin = origin;

    this.cache = {};
    this.memoize = memoize(this.cache);
  }

  get array() {
    return this.origin;
  }

  permissionFor(accessEntity) {
    const accessEntityType = accessEntity.modelType ?? accessEntity.type;

    return this._permissionsEntityMap[`${accessEntity.id}.${accessEntityType}`];
  }

  hasAdminAccess(entities = []) {
    return entities.find((entity) => ADMINS_ROLES.includes(this.permissionFor(entity)?.role));
  }

  get _permissionsEntityMap() {
    return this.memoize("_permissionsEntityMap", () => {
      return this.array.reduce((result, permission) => {
        const accessEntityType = permission.entity.modelType ?? permission.entity.type;

        return {
          ...result,
          [`${permission.entity.id}.${accessEntityType}`]: permission,
        };
      }, {});
    });
  }
}

PermissionsMap.fragment = gql`
  fragment PermissionsMapFragment on MemberPermissionPayload {
    id
    entity {
      id
      name
      uid
      type
    }
    role
    createdDate
    lastModifiedDate
  }
`;
