import { gql } from "@apollo/client";
import { delegate, serialize } from "utils/entities";

import { User } from "models/fragments/User";
import { Timezone } from "models/Timezone/Timezone";
import { PROJECT_MODEL_TYPE } from "models/types";

export class ProjectShort {
  constructor(origin) {
    this.origin = origin;

    delegate(this, origin);

    serialize(this, { timezone: Timezone, createdBy: User });
  }

  get timezoneId() {
    return this.timezone?.id;
  }

  get modelType() {
    return PROJECT_MODEL_TYPE;
  }
}

ProjectShort.fragment = gql`
  fragment ProjectShortFragment on GetProjectPayload {
    id
    name
    version
    email
    createdDate
    timezone {
      ...TimezoneFragment
    }
    createdBy {
      ...UserFragment
    }
  }

  ${User.fragment}
  ${Timezone.fragment}
`;
