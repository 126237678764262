import { gql } from "@apollo/client";
import { delegate, memoize, serialize } from "utils/entities";
import keyBy from "lodash/keyBy";
import compact from "lodash/compact";
import { FormField } from "models/abstract/FormField";
import { TableAccess } from "./TableAccess";

export class SecondaryTableAccessLink {
  constructor(origin) {
    this.origin = origin;
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin);

    serialize(this, { secondaryTable: TableAccess, primaryTable: TableAccess });

    this.mapping = this.origin.mapping || [];
  }

  get label() {
    return this.secondaryTable.name;
  }

  get mappingColumnsIdsMap() {
    return this.memoize("mappingColumnsIdsMap", () => {
      return this.mapping.reduce(
        (result, field) => ({ ...result, [field.primaryColumnId]: field.secondaryColumnId }),
        {}
      );
    });
  }

  get fields() {
    return this.memoize("fields", () => {
      const mappingFiedsMap = keyBy(this.mapping, "secondaryColumnId");
      const primaryColumnMap = keyBy(this.primaryTable.columns, "id");
      const secondaryColumnMap = keyBy(this.secondaryTable.columns, "id");

      const mappingFields = this.mapping.map((field) => {
        const secondaryColumn = secondaryColumnMap[field.secondaryColumnId];

        if (!secondaryColumn) return null;

        return {
          primaryColumn: primaryColumnMap[field.primaryColumnId],
          secondaryColumn,
          hidden: field.hidden,
          lookup: field.lookup,
        };
      });

      const componentFields = this.secondaryTable.columns.map((secondaryColumn) => {
        const mappingField = mappingFiedsMap[secondaryColumn.id];

        if (mappingField) return null;

        return {
          secondaryColumn,
          primaryColumn: null,
          hidden: true,
          lookup: false,
        };
      });

      return compact([...mappingFields, ...componentFields]);
    });
  }

  get formFields() {
    return this.memoize("formFields", () => {
      return this.fields
        .filter((field) => !field.hidden)
        .map(
          ({ secondaryColumn }) =>
            new FormField({
              id: secondaryColumn.id,
              fieldId: null,
              name: secondaryColumn.id,
              label: secondaryColumn.name,
              disabled: false,
              icon: null,
              type: secondaryColumn.type,
              subtype: secondaryColumn.subtype,
              editable: true,
              required: secondaryColumn.mandatory,
              config: secondaryColumn.config,
              placeholder: secondaryColumn.placeholder,
            })
        );
    });
  }
}

SecondaryTableAccessLink.fragment = gql`
  fragment SecondaryTableAccessLinkFragment on SecondaryTableAccessLink {
    id
    name
    uid
    secondaryTable {
      ...TableAccessFragment
    }
    primaryTable {
      ...TableAccessFragment
    }
    mapping {
      primaryColumnId
      secondaryColumnId
      hidden
      overwrite
      dynamic
      lookup
    }
  }

  ${TableAccess.fragment}
`;
