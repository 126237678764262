import React, { createContext, useContext, useState } from "react";
import SlashScreen from "components/SlashScreen";
import useMutation from "hooks/useMutation";
import isEmpty from "lodash/isEmpty";
import { useGetProfile } from "models/Profile/queries";
import { SIGN_IN_MUTATION } from "graphql/mutations";
import { fetchParameter } from "utils/url";
import { useNavigate } from "react-router-dom";

const ACCESS_TOKEN_KEY = "accessToken-ultimate-app";
const CONFIRM_TOKEN_KEY = "token";

export const fetchConfirmToken = () => fetchParameter(CONFIRM_TOKEN_KEY);
export const fetchAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  } else {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }
};

const AuthContext = createContext({
  login: () => Promise.resolve(),
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [redirectPath, setRedirectPath] = useState("/");
  const [signInUser] = useMutation(SIGN_IN_MUTATION);
  const [accessToken, setAccessToken] = useState(fetchAccessToken());

  const { profile, loading, refetch } = useGetProfile();

  const isAuthenticated = !!profile?.id && !isEmpty(accessToken);

  const setAccess = (newToken) => {
    setSession(newToken);
    setAccessToken(newToken);
    return refetch();
  };

  const login = ({ variables, onFailure }) => {
    return signInUser({
      variables: variables,
      onSuccess: ({ accessToken: newToken }) => {
        setAccess(newToken).then(() => navigate(redirectPath));
      },
      onFailure,
    });
  };

  const logout = () => {
    setSession(null);
    window.location.reload();
  };

  // useLogoutTimeout({
  //   isAuthenticated,
  //   onLogout: logout,
  //   tokenExpiryTime: 21600000, // 6 hours TODO: Discuss
  // });

  if (loading) {
    return <SlashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        signInUser,
        setAccess,
        setRedirectPath,
        currentProfile: profile,
        isAuthenticated,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

// const useLogoutTimeout = ({ isAuthenticated, onLogout, tokenExpiryTime }) => {
//   const [timeLeft, setTimeLeft] = useState(tokenExpiryTime);
//   useEffect(() => {
//     const logoutTimer = setTimeout(() => {
//       isAuthenticated && onLogout();
//     }, timeLeft);
//     return () => clearTimeout(logoutTimer);
//   }, [isAuthenticated, onLogout, timeLeft]);
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setTimeLeft((timeLeft) => timeLeft - 1000);
//     }, 1000);
//     return () => clearInterval(intervalId);
//   }, [isAuthenticated]);
//   return null;
// };

export default AuthContext;
