import React, { useEffect } from "react";
import NProgress from "nprogress";
import LinearProgress from "components/LinearProgress";
import Box from "components/Box";

import "styles/App/LoadingScreen.scss";

const useLoadingScreenStyles = () => ({
  root: "AppLoadingScreen-root",
});

const LoadingScreen = () => {
  const classes = useLoadingScreenStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;
