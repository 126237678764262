import React, { useState } from "react";
import { ListItem } from "@mui/material";
import Collapse from "components/Collapse";
import Box from "components/Box";
import IconButton from "components/IconButton";
import Typography from "components/Typography";
import Button from "components/Button";
import NavLink from "components/NavLink";
import CollapseButton from "components/Buttons/CollapseButton";
import clsx from "clsx";
import { useParams, useLocation, generatePath } from "react-router-dom";
import { ArrowUpIcon, MoreHorizontalIcon } from "icons";
import { useSideNavigationListStyles } from "theme/components/SideNavigation";
import isEmpty from "lodash/isEmpty";
import { useLayout } from "contexts/Layout";

import { AccessLoadingSection } from "containers/AccessSection";

const isEqualPath = (to, location, params) => {
  if (!to) return false;

  return generatePath(to, params) === location.pathname;
};

const SectionIcon = ({ icon: Icon }) => {
  return Icon ? (
    <Icon size="small" space={{ mr: 1 }} />
  ) : (
    <ArrowUpIcon size="small" style={{ visibility: "hidden" }} space={{ mr: 1 }} />
  );
};

const NestedNavLink = ({
  classes,
  to,
  icon,
  title,
  params,
  disabled,
  accessProps,
  active = false,
}) => (
  <AccessLoadingSection {...accessProps}>
    {({ loading }) => (
      <ListItem
        component={NavLink}
        classes={classes}
        className={clsx(classes.nested, { active })}
        activeClassName="active"
        exact
        disabled={disabled || !to || loading}
        to={to ? generatePath(to, params) : null}
      >
        <SectionIcon icon={icon} />

        <Typography>{title}</Typography>
      </ListItem>
    )}
  </AccessLoadingSection>
);

const NavigationItem = ({
  className,
  title,
  icon,
  to,
  nested,
  noMore,
  disabled = false,
  noHover = false,
  isActiveSection,
  onClick,
  ...props
}) => {
  const params = useParams();
  const location = useLocation();
  const toPath = to ? generatePath(to || "/:companyId", params) : null;

  const { hoveredSidebar } = useLayout();
  const classes = useSideNavigationListStyles();
  const nestedItems = nested || [];
  const isNested = !isEmpty(nested);

  const mainNestedItems = noMore ? nestedItems : nestedItems.slice(0, 3);
  const moreNestedItems = noMore ? [] : nestedItems.slice(3, nestedItems.length);

  const [open, setOpen] = useState(false);
  const [more, setMore] = useState(false);

  const isOpened = open && hoveredSidebar;

  const isActiveMainNested = !!mainNestedItems.find(
    (section) =>
      isEqualPath(section.to, location, params) || (isActiveSection && isActiveSection(section))
  );

  const disableParent = (event) => {
    event.stopPropagation();
  };

  const toggleMore = () => setMore((prev) => !prev);

  const toggleList = (event) => {
    event.preventDefault();
    disableParent(event);
    setOpen((prev) => !prev);
  };

  const handleClick = onClick || (toPath ? null : toggleList);

  return (
    <Box {...props}>
      <ListItem
        classes={classes}
        disabled={disabled}
        className={clsx(className, { noHover })}
        onClick={handleClick}
        {...(toPath
          ? {
              exact: true,
              button: true,
              activeClassName: isActiveMainNested ? "" : "active",
              component: toPath ? NavLink : null,
              to: toPath,
            }
          : {})}
      >
        {isNested ? (
          <CollapseButton
            open={isOpened}
            space={{ mr: 1 }}
            className={classes.cornerIcon}
            onMouseDown={disableParent}
            onClick={toggleList}
          />
        ) : (
          <IconButton
            space={{ mr: 1 }}
            size="xs"
            className={clsx(classes.cornerIcon, classes.emptyCornerIcon)}
          />
        )}

        {icon && <SectionIcon icon={icon} classes={classes} />}

        <Typography className={clsx(classes.cornerIcon)}>{title}</Typography>
      </ListItem>

      {isNested && (
        <Collapse className={classes.nestedMenu} in={isOpened}>
          <>
            {mainNestedItems.map((nestedSection, index) => (
              <NestedNavLink
                key={`${to}-${nested.to}-${index}`}
                {...nestedSection}
                classes={classes}
                params={params}
                disabled={disabled}
                active={isActiveSection && isActiveSection(nestedSection)}
              />
            ))}

            {moreNestedItems.length > 0 && (
              <>
                <Collapse in={more}>
                  {moreNestedItems.map((nestedSection, index) => (
                    <NestedNavLink
                      key={`${to}-${nested.to}-${index}`}
                      {...nestedSection}
                      classes={classes}
                      params={params}
                      disabled={disabled}
                      active={isActiveSection && isActiveSection(nestedSection)}
                    />
                  ))}
                </Collapse>

                <Button
                  leftIcon={MoreHorizontalIcon}
                  className={classes.moreButton}
                  onClick={toggleMore}
                  variant="hint"
                >
                  {more ? "Show less" : `${moreNestedItems.length} more`}
                </Button>
              </>
            )}
          </>
        </Collapse>
      )}
    </Box>
  );
};

export default NavigationItem;
