import { gql } from "@apollo/client";
import { delegate } from "utils/entities";

export class Content {
  constructor(origin) {
    this.origin = origin;

    delegate(this, origin);
  }
}

Content.fragment = gql`
  fragment ContentFragment on Content {
    id
    filename
    label
    url
    size
  }
`;
