import moment from "moment";

export const FORMATS = {
  time: "h:mm a",
  date: "MM/DD/yyyy",
  dateTime: "MM/DD/yyyy h:mm a",
};

export const timeAgo = (value) => (value ? moment(value).fromNow() : "");

export const timeString = (value, format = null) =>
  value ? moment(value).format(format || FORMATS.dateTime) : "";

export const dateString = (value, format = null) =>
  value ? moment(value).format(format || FORMATS.date) : "";

export const fromNow = (value) => (value ? moment(value).fromNow() : "");

export const parseBackendTimeFormat = (value) => value.replaceAll("d", "D").replaceAll("KK", "kk");
