import React from "react";
import Select from "components/Inputs/Select";
import { useSearchCompanies } from "models/Company/queries";
import { AvatarInitials } from "components/Avatar";
import Typography from "components/Typography";
import Divider from "components/Divider";
import Box from "components/Box";
import MenuItem from "components/Menu/MenuItem";
import { PlusIcon } from "icons";

export const CompanySelect = ({ onJoin, onCreate, ...props }) => {
  const { companies, totalCount, onLoadMore } = useSearchCompanies();

  return (
    <Select
      fullWidth
      maxHeight={248}
      placeholder="Select company"
      variant="outlined"
      menuLabel={`${totalCount} companies`}
      labelProperty="name"
      options={companies.map((company) => [company.id, company])}
      labelComponent={CountryLabelComponent}
      selectedLabelComponent={SelectedCountryLabelComponent}
      totalCount={totalCount}
      onLoadMore={onLoadMore}
      menuItemProps={{ size: "large" }}
      {...props}
      footer={() => (
        <>
          <Divider variant="light" space={{ mb: 1 }} />

          <MenuItem onClick={onJoin} icon={PlusIcon}>
            Join a company
          </MenuItem>

          <MenuItem onClick={onCreate} icon={PlusIcon}>
            Create a company
          </MenuItem>
        </>
      )}
    />
  );
};

const SelectedCountryLabelComponent = ({ option }) => {
  const company = option[1];

  return (
    <Box display="flex" alignItems="center">
      <AvatarInitials
        size="sidebar"
        name={company.name}
        style={{ marginLeft: 0 }}
        space={{ mr: 1 }}
      />

      <Typography weight="title" color="dark">
        {company.name}
      </Typography>
    </Box>
  );
};

const CountryLabelComponent = ({ option }) => {
  const company = option[1];

  return (
    <Box display="flex" alignItems="center">
      <AvatarInitials
        size="medium"
        name={company.name}
        style={{ marginLeft: 0 }}
        space={{ mr: 1.5 }}
      />

      <div>
        <Typography color="dark">{company.name}</Typography>
        <Typography color="hint" variant="caption">{`${999} members (TODO)`}</Typography>
      </div>
    </Box>
  );
};
