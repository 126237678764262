import React, { lazy, Suspense } from "react";
import { RelativeScreen } from "components/SlashScreen";

export const NotFound = lazy(() => import(/* webpackChunkName: "errors404" */ "views/errors/404"));

export const AccessDenied = lazy(() =>
  import(/* webpackChunkName: "errors403" */ "views/errors/403")
);

export const NewIconsKits = lazy(() =>
  import(/* webpackChunkName: "NewIconsKits" */ "views/NewIconsKits")
);

export const LoginPage = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ "views/auth/LoginPage")
);

export const SignUpPage = lazy(() =>
  import(/* webpackChunkName: "SignUpPage" */ "views/auth/SignUpPage")
);

export const ResetPasswordPage = lazy(() =>
  import(/* webpackChunkName: "ResetPasswordPage" */ "views/auth/ResetPasswordPage")
);

export const SuccessPage = lazy(() =>
  import(/* webpackChunkName: "SuccessPage" */ "views/auth/SuccessPage")
);

export const SignUpConfirmPage = lazy(() =>
  import(/* webpackChunkName: "SignUpConfirmPage" */ "views/auth/SignUpConfirmPage")
);

export const ResetPasswordConfirmPage = lazy(() =>
  import(/* webpackChunkName: "ResetPasswordConfirmPage" */ "views/auth/ResetPasswordConfirmPage")
);

export const FieldsPage = lazy(() => import(/* webpackChunkName: "FieldsPage" */ "views/Fields"));

export const RootPage = lazy(() => import(/* webpackChunkName: "RootPage" */ "views/Root"));

export const ListsPage = lazy(() => import(/* webpackChunkName: "ListsPage" */ "views/ListItems"));

export const AutomationPage = lazy(() =>
  import(/* webpackChunkName: "AutomationPage" */ "views/Automation")
);

export const TasksPage = lazy(() => import(/* webpackChunkName: "TasksPage" */ "views/TasksNew"));

export const TasksCreating = lazy(() =>
  import(/* webpackChunkName: "TasksCreating" */ "views/TasksCreating/Page")
);

export const ActionsCreating = lazy(() =>
  import(/* webpackChunkName: "ActionsCreating" */ "views/Actions/Page")
);

export const GeneralPage = lazy(() =>
  import(/* webpackChunkName: "GeneralPage" */ "views/Settings/Companies/General")
);

export const ProjectMembersPage = lazy(() =>
  import(/* webpackChunkName: "ProjectMembersPage" */ "views/Settings/Members/ProjectMembersPage")
);

export const CompanyMembersPage = lazy(() =>
  import(/* webpackChunkName: "CompanyMembersPage" */ "views/Settings/Members/CompanyMembersPage")
);

export const ProfileSettings = lazy(() =>
  import(/* webpackChunkName: "ProfileSettings" */ "views/Settings/ProfileSettings")
);

export const ChannelsPage = lazy(() =>
  import(/* webpackChunkName: "ChannelsPage" */ "views/Settings/Channels")
);

export const ChannelIntegrationPage = lazy(() =>
  import(/* webpackChunkName: "ChannelsPage" */ "views/Settings/Channels/IntegrationPage")
);

export const ChannelAuthStatusPage = lazy(() =>
  import(/* webpackChunkName: "ChannelAuthStatusPage" */ "views/Settings/Channels/AuthStatusPage")
);

export const ImportExportPage = lazy(() =>
  import(/* webpackChunkName: "ImportExportPage" */ "views/Settings/ImportExport")
);

export const KitsPage = lazy(() => import(/* webpackChunkName: "KitsPage" */ "views/Kits"));

export const TableProTestPage = lazy(() =>
  import(/* webpackChunkName: "TableProTestPage" */ "views/TableProTest")
);

export const KitsIcons = lazy(() => import(/* webpackChunkName: "KitsIcons" */ "views/KitsIcons"));

export const TablesPage = lazy(() => import(/* webpackChunkName: "TablesPage" */ "views/Tables"));

export const DevPage = lazy(() => import(/* webpackChunkName: "DevPage" */ "views/Dev"));

export const CreateCompanyPage = lazy(() =>
  import(/* webpackChunkName: "CreateCompanyPage" */ "views/Settings/Companies/New")
);

export const ProjectsNewPage = lazy(() =>
  import(/* webpackChunkName: "ProjectsNewPage" */ "views/Settings/Projects/New")
);

export const ProjectsViewPage = lazy(() =>
  import(/* webpackChunkName: "ProjectsViewPage" */ "views/Settings/Projects/View")
);

export const ProjectEditPage = lazy(() =>
  import(/* webpackChunkName: "ProjectEditPage" */ "views/Settings/Projects/Edit")
);

export const CompaniesViewPage = lazy(() =>
  import(/* webpackChunkName: "CompaniesViewPage" */ "views/Settings/Companies/View")
);

export const CompaniesEditPage = lazy(() =>
  import(/* webpackChunkName: "CompaniesEditPage" */ "views/Settings/Companies/Edit")
);

export const TableViewsViewPage = lazy(() =>
  import(/* webpackChunkName: "TableViewsView" */ "views/Settings/TableViews/View")
);

export const DefaultSuspense = (Component) => (
  <Suspense fallback={<div></div>}>
    <Component />
  </Suspense>
);

export const LoaderSuspense = (Component) => (
  <Suspense fallback={<RelativeScreen />}>
    <Component />
  </Suspense>
);

export const AuthSuspense = (Component) => (
  <Suspense fallback={<div></div>}>
    <Component />
  </Suspense>
);
export const PrivateSpacePage = lazy(() =>
  import(/* webpackChunkName: "CompaniesEditPage" */ "views/PrivateSpace/")
);
