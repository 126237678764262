import pickBy from "lodash/pickBy";

const queryKey = (query) => query.definitions[0].selectionSet.selections[0].name.value;

const SKIP_QUERY_PROPERTIES = {
  getCountries: ["namespace"],
  getCurrencies: ["namespace"],
  getTimezones: ["namespace"],
  getDateFormats: ["namespace"],
  getTimeFormats: ["namespace"],
  getProfile: ["namespace"],
  getSettings: ["namespace"],
  getCompany: ["namespace"],
  searchCompanies: ["namespace"],
  globalSearch: ["filter"],
  lookupItems: ["filter"],
};

const SKIP_MUTATION_PROPERTIES = {
  signUp: ["namespace"],
  signUpConfirm: ["namespace"],
  resetPassword: ["namespace"],
  resetPasswordConfirm: ["namespace"],
  signIn: ["namespace"],
  updateAvatar: ["namespace"],
  updateProfile: ["namespace"],
  updateSettings: ["namespace"],
  createCompany: ["namespace"],
  updateCompany: ["namespace"],
  updateCompanyAvatar: ["namespace"],
  changePassword: ["namespace"],
  changeEmail: ["namespace"],
};

export const filterSkipQueryProperties = (searchQuery, input) => {
  const skipQueryProperties = SKIP_QUERY_PROPERTIES[queryKey(searchQuery)];

  return skipQueryProperties
    ? pickBy(input, (_, key) => !skipQueryProperties.includes(key))
    : input;
};

export const filterSkipMutationProperties = (searchQuery, input) => {
  const skipQueryProperties = SKIP_MUTATION_PROPERTIES[queryKey(searchQuery)];

  return skipQueryProperties
    ? pickBy(input, (_, key) => !skipQueryProperties.includes(key))
    : input;
};
