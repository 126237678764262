import * as Yup from "yup";
import ERRORS from "constants/errors";
import { YupNumber, YupDate, YupDateTime } from "utils/yup";

import {
  AUTO_INCREMENT_COMPONENT_TYPE,
  STRING_COMPONENT_TYPE,
  TEXT_COMPONENT_TYPE,
  NUMBER_COMPONENT_TYPE,
  MULTI_SELECT_COMPONENT_TYPE,
  SINGLE_SELECT_COMPONENT_TYPE,
  OBJECT_COMPONENT_TYPE,
  PHONE_COMPONENT_TYPE,
  EMAIL_COMPONENT_TYPE,
  DATE_COMPONENT_TYPE,
  TIME_COMPONENT_TYPE,
  DATE_TIME_COMPONENT_TYPE,
  MEDIA_COMPONENT_TYPE,
  BOOLEAN_COMPONENT_TYPE,
  FORMULA_COMPONENT_TYPE,
  TABLE_CONNECTOR_COMPONENT_TYPE,
  HYPERLINK_COMPONENT_TYPE,
} from "components/ComponentType/types";

export class ComponentTypeYup {
  constructor({ componentType, componentTypeConfig, editable = true, required = false }) {
    this.componentType = componentType;
    this.componentTypeConfig = componentTypeConfig;
    this.editable = editable;
    this.required = required;
  }

  get yup() {
    if (!this.editable) return Yup.mixed().nullable();

    const yupField = COMPONENT_TYPES_YUP[this.componentType];

    if (yupField === null) return null;

    return this.required ? yupField.nullable().required(ERRORS.required) : yupField.nullable();
  }
}

export const COMPONENT_TYPES_YUP = {
  [AUTO_INCREMENT_COMPONENT_TYPE]: Yup.mixed(),
  [STRING_COMPONENT_TYPE]: Yup.mixed(),
  [TEXT_COMPONENT_TYPE]: Yup.mixed(),
  [NUMBER_COMPONENT_TYPE]: YupNumber,
  [MULTI_SELECT_COMPONENT_TYPE]: Yup.mixed(),
  [SINGLE_SELECT_COMPONENT_TYPE]: Yup.mixed(),
  [PHONE_COMPONENT_TYPE]: Yup.mixed(),
  [EMAIL_COMPONENT_TYPE]: Yup.mixed(),
  [DATE_COMPONENT_TYPE]: YupDate,
  [TIME_COMPONENT_TYPE]: Yup.mixed(),
  [DATE_TIME_COMPONENT_TYPE]: YupDateTime,
  [MEDIA_COMPONENT_TYPE]: Yup.mixed(),
  [BOOLEAN_COMPONENT_TYPE]: Yup.mixed(),
  [FORMULA_COMPONENT_TYPE]: Yup.mixed(),
  [HYPERLINK_COMPONENT_TYPE]: Yup.mixed(),
  [OBJECT_COMPONENT_TYPE]: Yup.mixed(),
  [TABLE_CONNECTOR_COMPONENT_TYPE]: Yup.mixed(),
};
