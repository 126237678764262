import { gql } from "@apollo/client";
import { delegate, memoize, serializeCollection } from "utils/entities";
import { TableColumn } from "models/fragments/TableColumn";

export class TableAccess {
  constructor(origin) {
    this.origin = { ...origin, ...origin.table };
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, this.origin);

    serializeCollection(this, { columns: TableColumn });
  }
}

TableAccess.fragment = gql`
  fragment TableAccessFragment on GetTableAccessPayload {
    id
    name
    uid
    columns {
      ...TableColumnFragment
    }
  }

  ${TableColumn.fragment}
`;
