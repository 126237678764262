import { variantsClassNames } from "theme/utils";

import "styles/App/Menu/Menu.scss";

export const useMenuStyles = () => ({
  list: "AppMenu-list",
  paper: "AppMenu-paper",
  header: "AppMenu-header",
  avatarTitle: "AppMenu-avatarTitle",
});

export const usePopoverStyles = () => ({
  list: "AppPopover-list",
  paper: "AppPopover-paper",
});

export const useMenuItemStyles = ({ size = "medium", color, disableRipple = false } = {}) => ({
  root: variantsClassNames("AppMenuItem-root", { size, color, disableRipple }),
  itemContent: "AppMenuItem-itemContent",
  tickIcon: "AppMenuItem-tickIcon",
});
