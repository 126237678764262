import React from "react";
import clsx from "clsx";
import { useSvgIconStyles } from "theme/components/SvgIcon";
import { SvgIcon as MuiSvgIcon } from "@mui/material";
import Box from "components/Box";
import withStyles from "components/withStyles";

const SvgIconComponent = withStyles(({ innerRef, ...props }) => (
  <MuiSvgIcon ref={innerRef} {...props} />
));

const SvgIcon = ({
  className,
  component: IconComponent,
  rotate = 0,
  size = "medium",
  space,
  color,
  circle,
  property,
  ...props
}) => {
  const classes = useSvgIconStyles({ size, color, property });

  if (circle) {
    return (
      <Box
        component="span"
        space={space}
        className={clsx("SvgIconWrap", classes.box, classes.circle)}
      >
        <SvgIconComponent
          component={(iconProps) => (
            <IconComponent
              {...props}
              className={clsx(
                classes.root,
                iconProps.className,
                className,
                IconComponent.render.name
              )}
              style={{ ...props.style, transform: rotate ? `rotate(${rotate}deg)` : undefined }}
            />
          )}
        />
      </Box>
    );
  }

  return (
    <SvgIconComponent
      space={space}
      component={(iconProps) => (
        <IconComponent
          {...props}
          className={clsx(classes.root, iconProps.className, className, IconComponent.render.name)}
          style={{ ...props.style, transform: rotate ? `rotate(${rotate}deg)` : undefined }}
        />
      )}
    />
  );
};

export default SvgIcon;

export const SvgProxy = (IconComponent) => (props) =>
  <SvgIcon {...props} component={IconComponent} />;
