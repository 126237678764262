import React from "react";
import { useOpener } from "utils/state";
import { useGlobalSearchBarStyles } from "theme/components/GlobalSearch";
import SearchEntities from "containers/GlobalSearch/SearchEntities";
import { SideDrawer } from "components/Drawer";

const GlobalSearchContext = React.createContext(null);

export const useGlobalSearch = () => React.useContext(GlobalSearchContext);

export const GlobalSearchProvider = ({ children }) => {
  const classes = useGlobalSearchBarStyles();
  const [open, handleOpen, handleClose, toggleOpen] = useOpener(false);

  return (
    <GlobalSearchContext.Provider
      value={{
        open,
        handleOpen,
        handleClose,
        toggleOpen,
      }}
    >
      {children}

      <SideDrawer open={open} classes={classes}>
        <SearchEntities />
      </SideDrawer>
    </GlobalSearchContext.Provider>
  );
};
