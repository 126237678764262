import { gql } from "@apollo/client";
import { delegate, serialize } from "utils/entities";

import { Content } from "models/fragments/Content";

export class User {
  constructor(origin) {
    this.origin = origin;

    delegate(this, origin, ["displayName"]);
    serialize(this, { avatar: Content });
  }

  get displayName() {
    return this.origin.displayName || this.origin.fullName;
  }
}

User.fragment = gql`
  fragment UserFragment on User {
    id
    displayName
    firstName
    lastName
    fullName
    email
    avatar {
      ...ContentFragment
    }
  }

  ${Content.fragment}
`;
