import { useQuery } from "@apollo/client";
import { useWorkspace } from "contexts/Workspace";

import { filterSkipQueryProperties } from "./filterProperties";

const useQueryProxy = (query, { input = {}, loading = false, skip, ...rest } = {}) => {
  const { namespace } = useWorkspace() || {};

  const queryProps = {
    ...rest,
    skip: skip || loading,
    variables: {
      ...rest.variables,
      input: filterSkipQueryProperties(query, { ...input, namespace }),
    },
  };

  const { data = {}, ...restResponse } = useQuery(query, queryProps);
  const dataResponse = Object.values(data || {})[0];
  const { content } = dataResponse || {};

  if (loading) {
    return {
      loading: true,
    };
  }

  return {
    data,
    payload: dataResponse,
    content,
    ...restResponse,
  };
};

export default useQueryProxy;
