import React, { useEffect } from "react";
import { useAuth } from "contexts/Auth";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import { AuthProvider } from "contexts/Auth";

const AuthRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setRedirectPath } = useAuth();

  useEffect(() => {
    setRedirectPath(location.pathname);
    navigate("/auth/login");
    // eslint-disable-next-line
  }, []);

  return <LoadingScreen />;
};

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <AuthRedirect />;
  }

  return <>{children}</>;
};

const AuthGuardWrap = (props) => (
  <AuthProvider>
    <AuthGuard {...props} />
  </AuthProvider>
);

export default AuthGuardWrap;
