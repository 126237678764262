import React, { useState, useEffect } from "react";
import { Collapse as MuiCollapse } from "@mui/material";

const Collapse = ({ in: open, children, ...rest }) => {
  const [timeOutId, setTimeOutId] = useState(null);
  const [renderContent, setRenderContent] = useState(false);

  useEffect(() => {
    timeOutId && clearTimeout(timeOutId);

    if (open) {
      setRenderContent(true);
    } else {
      const timerId = setTimeout(() => setRenderContent(false), 500);
      setTimeOutId(timerId);
    }
  }, [open]);

  return (
    <MuiCollapse in={open} {...rest}>
      {renderContent && children}
    </MuiCollapse>
  );
};

export default Collapse;
