import React from "react";
import GlobalSearchContent from "components/GlobalSearch/Content";
import { useGlobalSearchQuery } from "models/GlobalSearch/queries";
import { useRouteMap } from "hooks/useRouteMap";
import * as paths from "routes/path";
import { useGlobalSearch } from "./Provider";

const EntitiesDrawer = () => {
  const { navigateToPath } = useRouteMap();
  const { handleClose } = useGlobalSearch();
  const { searchItems, loading, search, setSearch, onLoadMore, totalCount } =
    useGlobalSearchQuery();

  const navigateToItem = (entity) => {
    navigateToPath(paths.PROJECT_VIEW_ITEM, { tableViewId: entity.tableId, id: entity.id });
    handleClose();
  };

  return (
    <GlobalSearchContent
      loading={loading}
      entities={searchItems}
      search={search}
      onSearch={setSearch}
      onLoadMore={onLoadMore}
      totalCount={totalCount}
      onSelect={navigateToItem}
    />
  );
};

export default EntitiesDrawer;
