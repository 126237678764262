import React from "react";
import clsx from "clsx";

const getOS = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator?.userAgentData?.platform ?? window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K", "macOS"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "macOS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "windows";
  } else if (/Android/.test(userAgent)) {
    os = "android";
  } else if (!os && /Linux/.test(platform)) {
    os = "linux";
  } else {
    os = "other";
  }

  return os;
};

const OsProvider = ({ children }) => {
  const currentOS = getOS();

  return (
    <div className={clsx("os-content", { "custom-scroll": currentOS !== "macOS" })} id={currentOS}>
      {children}
    </div>
  );
};

export default OsProvider;
