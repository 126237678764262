import { TypeNumberIcon } from "icons";

export const AUTO_INCREMENT_COMPONENT_TYPE = "AUTO_INCREMENT_COMPONENT_TYPE";
export const STRING_COMPONENT_TYPE = "STRING_COMPONENT_TYPE";
export const TEXT_COMPONENT_TYPE = "TEXT_COMPONENT_TYPE";
export const NUMBER_COMPONENT_TYPE = "NUMBER_COMPONENT_TYPE";
export const MULTI_SELECT_COMPONENT_TYPE = "MULTI_SELECT_COMPONENT_TYPE";
export const SINGLE_SELECT_COMPONENT_TYPE = "SINGLE_SELECT_COMPONENT_TYPE";
export const OBJECT_COMPONENT_TYPE = "OBJECT_COMPONENT_TYPE";
export const PHONE_COMPONENT_TYPE = "PHONE_COMPONENT_TYPE";
export const EMAIL_COMPONENT_TYPE = "EMAIL_COMPONENT_TYPE";
export const DATE_COMPONENT_TYPE = "DATE_COMPONENT_TYPE";
export const TIME_COMPONENT_TYPE = "TIME_COMPONENT_TYPE";
export const DATE_TIME_COMPONENT_TYPE = "DATE_TIME_COMPONENT_TYPE";
export const MEDIA_COMPONENT_TYPE = "MEDIA_COMPONENT_TYPE";
export const BOOLEAN_COMPONENT_TYPE = "BOOLEAN_COMPONENT_TYPE";
export const FORMULA_COMPONENT_TYPE = "FORMULA_COMPONENT_TYPE";
export const TABLE_CONNECTOR_COMPONENT_TYPE = "TABLE_CONNECTOR_COMPONENT_TYPE";
export const HYPERLINK_COMPONENT_TYPE = "HYPERLINK_COMPONENT_TYPE";

const FIELD_VIEW_TYPES_ICONS = {
  [TABLE_CONNECTOR_COMPONENT_TYPE]: TypeNumberIcon,
  [AUTO_INCREMENT_COMPONENT_TYPE]: TypeNumberIcon,
  [STRING_COMPONENT_TYPE]: TypeNumberIcon,
  [TEXT_COMPONENT_TYPE]: TypeNumberIcon,
  [NUMBER_COMPONENT_TYPE]: TypeNumberIcon,
  [MULTI_SELECT_COMPONENT_TYPE]: TypeNumberIcon,
  [SINGLE_SELECT_COMPONENT_TYPE]: TypeNumberIcon,
  [OBJECT_COMPONENT_TYPE]: TypeNumberIcon,
  [PHONE_COMPONENT_TYPE]: TypeNumberIcon,
  [EMAIL_COMPONENT_TYPE]: TypeNumberIcon,
  [DATE_COMPONENT_TYPE]: TypeNumberIcon,
  [TIME_COMPONENT_TYPE]: TypeNumberIcon,
  [DATE_TIME_COMPONENT_TYPE]: TypeNumberIcon,
  [MEDIA_COMPONENT_TYPE]: TypeNumberIcon,
  [HYPERLINK_COMPONENT_TYPE]: TypeNumberIcon,
  [BOOLEAN_COMPONENT_TYPE]: TypeNumberIcon,
  [FORMULA_COMPONENT_TYPE]: TypeNumberIcon,
};

export const fetchComponentTypeIcon = (componentType) => FIELD_VIEW_TYPES_ICONS[componentType];

export const isDatableType = (componentType) =>
  [DATE_TIME_COMPONENT_TYPE, DATE_COMPONENT_TYPE, TIME_COMPONENT_TYPE].includes(componentType);
