import React from "react";
import Box from "components/Box";
import { SadIcon } from "icons";
import "styles/App/Alerts.scss";

const useErrorAlertStyles = () => ({
  root: "AppAlert-root AppAlert-root--error",
  iconContainer: "AppAlert-root__icon",
});

export const ErrorAlert = ({ children, ...props }) => {
  const classes = useErrorAlertStyles();

  return (
    <Box {...props} className={classes.root}>
      <Box className={classes.iconContainer} space={{ mr: 1 }}>
        <SadIcon />
      </Box>
      {children}
    </Box>
  );
};

export default ErrorAlert;
