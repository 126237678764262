import { gql } from "@apollo/client";
import { delegate, serialize, memoize, extender } from "utils/entities";
import { Content } from "models/fragments/Content";
import { User } from "models/fragments/User";

export class MemberShort {
  constructor(origin, { delegateProperties = ["email"] } = {}) {
    this.origin = { ...origin.user, ...origin };

    delegate(this, this.origin, [...delegateProperties, "displayName"]);
    serialize(this, { avatar: Content, createdBy: User });

    this.cache = {};
    this.extender = extender;
    this.memoize = memoize(this.cache);
  }

  get label() {
    return this.displayName || this.email;
  }

  get displayName() {
    return this.origin.displayName || this.origin.fullName;
  }

  get email() {
    return this.origin?.email || this.origin.inviteEmail;
  }

  get isInvited() {
    return this.status === "INVITED";
  }

  get isJoined() {
    return this.status === "JOINED";
  }

  get statusLabel() {
    return this.status;
  }
}

export const MEMBER_SHORT_FRAGMENT = `
  id
  inviteEmail
  status
  user {
    ...UserFragment
  }
`;

MemberShort.fragment = gql`
  fragment MemberShortFragment on MemberPayload {
    ${MEMBER_SHORT_FRAGMENT}
  }

  ${Content.fragment}
  ${User.fragment}
`;
