import React from "react";
import Box from "components/Box";
import flatten from "lodash/flatten";
import { isBlank } from "utils/detect";
import clsx from "clsx";
import Typography from "components/Typography";
import Label from "components/Label";
import { CrossXsIcon } from "icons";
import { useSelectStyles } from "theme/components/Select";
import { ArrowDownIcon } from "icons";

const RenderTags = ({
  emptyPlaceholder = "Select option",
  size,
  iconSize,
  variant,
  weight,
  inputVariant,
  tagVariant,
  isEmptyOptions,
  labelComponent: LabelComponent,
  getTagProps,
  getOption,
  getOptionLabel,
  value,
}) => {
  const classes = useSelectStyles({ size, variant });
  const values = flatten([value]);
  const isMarkSelect = inputVariant === "ghostOutlined";

  if ((emptyPlaceholder && isBlank(value)) || (!isBlank(value) && isEmptyOptions)) {
    return (
      <Box display="flex" alignItems="center">
        <Typography className={classes.placeholder}>{emptyPlaceholder}</Typography>
        {isMarkSelect && <ArrowDownIcon size={iconSize} className={classes.maskSelectIcon} />}
      </Box>
    );
  }

  if (tagVariant === "multiline") {
    return (
      <Box className="AppInputTags-root" style={{ display: "table" }}>
        {values.map((value, index) => {
          const isLast = index + 1 === values.length;
          const option = getOption({ value });

          if (!option) return <></>;

          const label = getOptionLabel(option);
          const { onDelete, ...tagProps } = getTagProps({ index, value });

          return (
            <Label
              {...tagProps}
              component="div"
              className={clsx(classes.selectValue, "AppInputTags-tag")}
              weight={weight}
              style={{ float: "left", marginRight: isLast ? 0 : "6px" }}
            >
              <Box display="flex" alignItems="center">
                {LabelComponent ? (
                  <LabelComponent option={option} value={value} label={label} entity={label} />
                ) : (
                  label
                )}

                <CrossXsIcon
                  onMouseDown={onDelete}
                  space={{ ml: 0.5 }}
                  style={{ display: "block", cursor: "pointer" }}
                  size="small"
                />
              </Box>
            </Label>
          );
        })}

        {isMarkSelect && (
          <ArrowDownIcon size={iconSize} className={clsx(classes.maskSelectIcon, "multiline")} />
        )}
      </Box>
    );
  }

  const renderValues = values.slice(0, 2);
  const otherValues = values.slice(2, values.length);

  return (
    <Box display="flex" alignItems="center">
      {renderValues.map((value, index) => {
        const withComma = renderValues.length > 0 && index + 1 !== renderValues.length;
        const option = getOption({ value });

        if (!option) return <></>;

        const label = getOptionLabel(option);

        return (
          <Typography
            component="div"
            key={`${index}-${value}`}
            className={classes.selectValue}
            weight={weight}
            space={{ mr: withComma ? 0.5 : 0 }}
            style={{
              display: "flex",
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {LabelComponent ? (
              <LabelComponent option={option} value={value} label={label} entity={label} />
            ) : (
              label
            )}
            {withComma ? "," : ""}
          </Typography>
        );
      })}

      {otherValues.length > 0 && (
        <Typography weight={weight} space={{ ml: 1, width: 15 }}>
          {"+ "}
          {otherValues.length}
        </Typography>
      )}

      {isMarkSelect && <ArrowDownIcon size={iconSize} className={classes.maskSelectIcon} />}
    </Box>
  );
};

export default RenderTags;
