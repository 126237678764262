import { gql } from "@apollo/client";
import fragments from "graphql/fragments";
import { useMemo } from "react";
import usePaginationQuery from "hooks/useQuery/usePaginationQuery";
import useQuery from "hooks/useQuery";
import { CompanyFull } from "./CompanyFull";
import { CompanyShort } from "./CompanyShort";

export const GET_COMPANY = gql`
  query GetCompany($input: GetCompanyInput) {
    getCompany(input: $input) {
      ...CompanyFullFragment
    }
  }
  ${CompanyFull.fragment}
`;

export const GET_COMPANIES = gql`
  query SearchCompanies($input: SearchCompaniesInput) {
    searchCompanies(input: $input) {
      ${fragments.PAGINATION_FRAGMENT}
      content {
        ...CompanyShortFragment
      }
    }
  }

  ${CompanyShort.fragment}
`;

export const useSearchCompanies = () => {
  const { content = [], ...props } = usePaginationQuery(GET_COMPANIES, {
    input: { sort: [{ field: "createdDate", value: "ASC" }] },
  });

  const companies = useMemo(() => content.map((company) => new CompanyShort(company)), [content]);

  return {
    companies,
    ...props,
  };
};

export const useGetCompany = (id) => {
  const { payload, loading, ...rest } = useQuery(GET_COMPANY, {
    skip: !id,
    input: { id: id },
  });

  const company = loading || !payload ? null : new CompanyFull(payload);

  return { company, payload, loading, ...rest };
};
