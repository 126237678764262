import { TABLE_VIEW_MODEL_TYPE, PROJECT_MODEL_TYPE } from "models/types";

export class AccessManager {
  constructor({ accessRules, user, company, permissionsConfig }) {
    this.accessRules = accessRules;
    this.user = user;
    this.company = company;
    this.userPermissionsMap = company?.permissionsMap;
    this.permissionsConfig = permissionsConfig;

    this.valid = !!this.company && !!this.userPermissionsMap && !!this.permissionsConfig;
  }

  hasAccess(accessRule, accessEntity) {
    if (!this.valid) return false;

    return accessRule.hasManagerAccess(this, accessEntity);
  }

  hasPermissionTypeAccess(permissionType, accessEntity) {
    if (!this.valid) return false;

    const requiredRoles = this.permissionsConfig.typeRolesMap[permissionType] || [];

    return !!requiredRoles.find((requiredRole) => this.hasRoleAccess(requiredRole, accessEntity));
  }

  // PRIVATE

  hasRoleAccess(requiredRole, accessEntity) {
    if (!accessEntity) return false;

    const accessEntityType = accessEntity.modelType ?? accessEntity.type;
    const entityRole = this.userPermissionsMap?.permissionFor(accessEntity)?.role;

    if (accessEntityType === TABLE_VIEW_MODEL_TYPE) {
      if (accessEntity.createdBy.id === this.user.id) return true;

      return (
        compareRoleAccess(requiredRole, entityRole) ||
        this.hasRoleAccess(requiredRole, accessEntity.project) ||
        this.hasRoleAccess(requiredRole, this.company)
      );
    }

    if (accessEntityType === PROJECT_MODEL_TYPE) {
      return (
        compareRoleAccess(requiredRole, entityRole) ||
        this.hasRoleAccess(requiredRole, this.company)
      );
    }

    return compareRoleAccess(requiredRole, entityRole);
  }

  get canViewSettings() {
    const userPermissions = this.userPermissionsMap?.array || [];
    const projects = userPermissions
      .filter((permission) => permission.entity.type === PROJECT_MODEL_TYPE)
      .map(({ entity }) => entity);

    return (
      this.hasAccess(this.accessRules.VIEW_COMPANY_SETTINGS, this.company) ||
      projects.some((accessEntity) =>
        this.hasAccess(this.accessRules.VIEW_PROJECT_SETTINGS, accessEntity)
      )
    );
  }

  get canModifyFields() {
    return this.hasAccess(this.accessRules.MODIFY_FIELDS, this.company);
  }

  get canRemoveProjects() {
    return this.hasAccess(this.accessRules.REMOVE_COMPANY_PROJECTS, this.company);
  }

  get canRemoveCompany() {
    return this.hasAccess(this.accessRules.REMOVE_COMPANY, this.company);
  }

  get canModifyTables() {
    return this.hasAccess(this.accessRules.MODIFY_TABLES, this.company);
  }

  get canModifyTasks() {
    return this.hasAccess(this.accessRules.MODIFY_TASKS, this.company);
  }

  get canCreateProject() {
    return this.hasAccess(this.accessRules.CREATE_COMPANY_PROJECT, this.company);
  }

  get canModifyOrgOwner() {
    return this.hasAccess(this.accessRules.MODIFY_ORG_OWNER, this.company);
  }
}

const compareRoleAccess = (requiredRole, roleToCheck) => {
  if (!roleToCheck) return false;

  switch (requiredRole) {
    case "COMPANY_OWNER":
      return requiredRole === roleToCheck;
    case "COMPANY_ADMIN":
      return requiredRole === roleToCheck || compareRoleAccess("COMPANY_OWNER", roleToCheck);
    case "COMPANY_MEMBER":
      return requiredRole === roleToCheck || compareRoleAccess("COMPANY_ADMIN", roleToCheck);
    case "COMPANY_GUEST":
      return requiredRole === roleToCheck || compareRoleAccess("COMPANY_MEMBER", roleToCheck);
    case "PROJECT_OWNER":
      return requiredRole === roleToCheck || compareRoleAccess("COMPANY_ADMIN", roleToCheck);
    case "PROJECT_ADMIN":
      return requiredRole === roleToCheck || compareRoleAccess("PROJECT_OWNER", roleToCheck);
    case "PROJECT_EDITOR":
      return requiredRole === roleToCheck || compareRoleAccess("PROJECT_ADMIN", roleToCheck);
    case "PROJECT_VIEWER":
      return requiredRole === roleToCheck || compareRoleAccess("PROJECT_EDITOR", roleToCheck);
    case "TABLE_VIEW_EDITOR":
      return requiredRole === roleToCheck || compareRoleAccess("PROJECT_ADMIN", roleToCheck);
    case "TABLE_VIEW_VIEWER":
      return requiredRole === roleToCheck || compareRoleAccess("TABLE_VIEW_EDITOR", roleToCheck);
    default:
      return false;
  }
};

export class AccessRule {
  constructor({ any, all }) {
    this.any = any || [];
    this.all = all || [];
  }

  hasManagerAccess(accessManager, accessEntity) {
    return (
      this._checkAnyRules(accessManager, accessEntity) &&
      this._checkAllRules(accessManager, accessEntity)
    );
  }

  // PRIVATE

  _checkAnyRules(accessManager, accessEntity) {
    if (this.any.length === 0) return true;

    return this.any.some((type) => accessManager.hasPermissionTypeAccess(type, accessEntity));
  }

  _checkAllRules(accessManager, accessEntity) {
    if (this.all.length === 0) return true;

    return this.all.every((type) => accessManager.hasPermissionTypeAccess(type, accessEntity));
  }
}

const PROJECT_PERMISSIONS = ["PROJECT_OWNER", "PROJECT_EDITOR", "PROJECT_ADMIN", "PROJECT_VIEWER"];
const COMPANY_PERMISSIONS = ["COMPANY_OWNER", "COMPANY_ADMIN", "COMPANY_MEMBER", "COMPANY_GUEST"];
const TABLE_VIEW_PERMISSIONS = ["TABLE_VIEW_EDITOR", "TABLE_VIEW_VIEWER"];

export const PROJECT_PERMISSIONS_OPTIONS = PROJECT_PERMISSIONS.map((permission) => [
  permission,
  permission,
]);

export const COMPANY_PERMISSIONS_OPTIONS = COMPANY_PERMISSIONS.map((permission) => [
  permission,
  permission,
]);

export const TABLE_VIEW_PERMISSIONS_OPTIONS = TABLE_VIEW_PERMISSIONS.map((permission) => [
  permission,
  permission,
]);
