import React from "react";
import PropTypes from "prop-types";
import { CssBaseline } from "@mui/material";
import Typography from "components/Typography";
import { useLayoutStyles } from "theme/components/AuthLayout";
import { Outlet } from "react-router-dom";

const ProjectLayout = () => {
  const classes = useLayoutStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />

      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.header}>
            <Typography weight="bold" variant="h4" className={classes.logo}>
              Ultimate CRM
            </Typography>
          </div>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectLayout.propTypes = {
  children: PropTypes.node,
};

export default ProjectLayout;
