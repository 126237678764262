import { variantsClassNames } from "theme/utils";

import "styles/App/SearchMenu.scss";

export const useSearchMenu = ({ size } = {}) => ({
  paper: "AppSearchMenu-paper",
  list: "AppSearchMenu-list",
  container: "AppSearchMenu-container",
  groupTitle: "AppSearchMenu-groupTitle",
  contentContainer: "AppSearchMenu-contentContainer",
  scrollContainer: "AppSearchMenu-scrollContainer",
  scrollContent: variantsClassNames("AppSearchMenu-scrollContent", { size }),
  searchContainer: variantsClassNames("AppSearchMenu-searchContainer", { size }),
  item: "AppSearchMenu-item",
  anchorElement: "AppSearchMenu-anchorElement",
  ghostFooter: "AppSearchMenu-ghostFooter",
  footer: variantsClassNames("AppSearchMenu-footer", { size }),
});
