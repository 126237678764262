const ERRORS = {
  required: "This is required",
  email: "Must be an email format",
  date: "Must be an date format",
  dateTime: "Must be an date time format",
  confirmPassword: "Must match with Password",
  onlyLetters: "Must contain only letters",
  number: "This is required. Must contain a number",
};

export default ERRORS;
