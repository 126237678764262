import palette from "./palette";

const base = {
  fontWeight: 400,
  color: palette.common.black,
};

const FONTS = ["Apercu Pro"];

const typography = {
  fontFamily: FONTS.join(","),
  color: palette.common.black,
  button: {
    color: palette.common.black,
    textTransform: "capitalize",
    fontWeight: 400,
    fontSize: 14,
  },
  h1: {
    ...base,
    fontSize: 32,
    fontWeight: 500,
  },
  h2: {
    ...base,
    fontSize: 24,
    fontWeight: 500,
  },
  h3: {
    ...base,
    fontSize: 20,
    fontWeight: 500,
  },
  h4: {
    ...base,
    fontSize: 16,
    fontWeight: 500,
  },
  body1: {
    ...base,
    fontSize: 14,
  },
  body2: {
    ...base,
    fontSize: 13,
  },
  caption: {
    ...base,
    fontSize: 12,
  },
  helperText: {
    ...base,
    fontSize: 11,
  },
  overline: {
    ...base,
    fontWeight: 500,
  },
  title: {
    ...base,
    fontSize: 24,
    color: palette.text.secondary,
    fontWeight: 500,
  },
  description: {
    ...base,
    fontSize: 12,
    color: palette.grey[60],
  },
};

const px = (value) => `${value}px`;

export const typographyPx = {
  ...typography,
  fontSize: px(typography.fontSize),
  button: {
    ...typography.button,
    fontSize: px(typography.button.fontSize),
  },
  h1: {
    ...typography.h1,
    fontSize: px(typography.h1.fontSize),
  },
  h2: {
    ...typography.h2,
    fontSize: px(typography.h2.fontSize),
  },
  h3: {
    ...typography.h3,
    fontSize: px(typography.h3.fontSize),
  },
  h4: {
    ...typography.h4,
    fontSize: px(typography.h4.fontSize),
  },
  body1: {
    ...typography.body1,
    fontSize: px(typography.body1.fontSize),
  },
  body2: {
    ...typography.body2,
    fontSize: px(typography.body2.fontSize),
  },
  caption: {
    ...typography.caption,
    fontSize: px(typography.caption.fontSize),
  },
  helperText: {
    ...typography.helperText,
    fontSize: px(typography.helperText.fontSize),
  },
  overline: {
    ...typography.overline,
    fontSize: px(typography.overline.fontSize),
  },
  title: {
    ...typography.title,
    fontSize: px(typography.title.fontSize),
  },
  description: {
    ...typography.description,
    fontSize: px(typography.description.fontSize),
  },
};

export default typography;
