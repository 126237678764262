const caller = function (obj, method) {
  if (method === ".") return obj;

  for (let i = 0, path = method.split("."), len = path.length; i < len; i++) {
    if (obj[path[i]] === null || obj[path[i]] === undefined) return;
    obj = obj[path[i]];
  }

  return obj;
};

export default caller;
