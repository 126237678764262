import React, { useState, useRef, useMemo, useEffect } from "react";
import clsx from "clsx";
import Typography from "components/Typography";
import Divider from "components/Divider";
import Box from "components/Box";
import { useSearchMenu } from "theme/components/SearchMenu";
import Button from "components/Button";
import { SearchIcon } from "icons";
import { searchOptions } from "utils/search";
import caller from "utils/caller";
import { getFieldTextWidth, findLongestOption } from "utils/tablePro";
import groupBy from "lodash/groupBy";
import compact from "lodash/compact";
import Input from "components/Inputs/Input";
import JSum from "jsum";
import MenuItem from "components/Menu/MenuItem";

import useSelect from "./useSelect";
import RenderOptions from "../RenderOptions";

const LOADING_OPTIONS = new Array(20).fill(["stubLoading", "stubLoading"]);

const OPTION_PADDING = 32;

const SelectOptions = ({
  fullHeight = false,
  disabled,
  options: selectOptions = [],
  labelProperty = null,
  groupProperty = null,
  menuLabel,
  allowBlank,
  dataValue,
  onChange,
  onClean,
  multiple = false,
  searchable = false,
  deSelect = null,
  virtualized = false,
  markVariant = "tick",
  onClose,
  maxHeight,
  onSelect: onSelectCallback,
  footer: Footer,
  labelComponent,
  totalCount,
  onLoadMore,
  onSearch,
  menuItemProps = {},
}) => {
  const menuRef = useRef();
  const menuClasses = useSearchMenu();

  const [searchValue, setSearchValue] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);

  const options = loadingMore ? [...selectOptions, ...LOADING_OPTIONS] : selectOptions;

  const handleLoadMore = () => {
    if (!onLoadMore) return;
    if (options.length === totalCount) return;

    setLoadingMore(true);

    onLoadMore().then(() => {
      setLoadingMore(false);
    });
  };

  const renderOptions = searchOptions(
    options,
    searchValue,
    compact([groupProperty, "name", "label"])
  );

  const groupedOptionsMap = groupProperty
    ? groupBy(renderOptions, ([_id, value]) => caller(value, groupProperty))
    : {};

  const groupedOptions = groupProperty
    ? Object.keys(groupedOptionsMap).reduce(
        (result, group, index) => [
          ...result,
          { group, index, key: `${group}-${index}`, options: groupedOptionsMap[group] },
        ],
        []
      )
    : renderOptions;

  const { setSelectValue, selectValue, onSelect, isSelectedValue } = useSelect({
    value: dataValue,
    onChange,
    multiple,
  });

  const handleClean = () => {
    setSelectValue(multiple ? [] : "");
    onClean && onClean();
  };

  const handleSelect = (value, checked) => () => {
    onSelect(value, checked);
    onSelectCallback && onSelectCallback(value, checked);
  };

  const maxOptionWidth = useMemo(() => {
    return getFieldTextWidth(null, findLongestOption(options, labelProperty)) + OPTION_PADDING;
  }, [JSum.digest(options, "SHA256", "hex")]);

  const width = Math.min(Math.max(200, maxOptionWidth), 400);

  useEffect(() => {
    if (loadingMore) return;
    if (!onSearch) return;
    if (searchValue === null) return;

    if (searchValue?.length <= 2) {
      onSearch("");
      return;
    }

    const timeOutId = setTimeout(() => onSearch(searchValue), 500);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <Box style={{ minWidth: width }} ref={menuRef} className={menuClasses.container}>
      <Box
        className={clsx(
          menuClasses.contentContainer,
          menuClasses.scrollContainer,
          "virtualized-scroll",
          {
            virtualized,
          }
        )}
      >
        {searchable && (
          <Box className={menuClasses.searchContainer}>
            <Input
              space={{ mb: 1 }}
              fullWidth
              autoFocus
              placeholder="Search"
              leftIcon={SearchIcon}
              variant="outlined"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value || "")}
            />

            {multiple && selectValue.length > 0 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                space={{ mb: 1, mt: 1 }}
              >
                <Button onMouseDown={handleClean} space={{ p: 1, py: 0 }} size="small">
                  Clear all
                </Button>
              </Box>
            )}
          </Box>
        )}

        <Box className={menuClasses.searchContainer}>
          {menuLabel && (
            <Typography space={{ p: 1 }} component="div" color="grey" variant="caption">
              {menuLabel}
            </Typography>
          )}

          {deSelect && (
            <>
              <MenuItem
                disabled={disabled}
                markVariant="crossCheckbox"
                selected={false}
                onMouseDown={disabled ? null : handleClean}
                tickIcon={false}
                {...menuItemProps}
              >
                {deSelect}
              </MenuItem>

              <Divider variant="light" />
            </>
          )}
        </Box>

        <Box
          component="ul"
          className={clsx(menuClasses.scrollContent, {
            virtualized,
          })}
        >
          {allowBlank && !multiple && (
            <MenuItem
              disabled={disabled}
              selected={isSelectedValue("")}
              onMouseDown={disabled ? null : handleSelect("", isSelectedValue(""))}
              tickIcon={false}
              {...menuItemProps}
            >
              None
            </MenuItem>
          )}

          <RenderOptions
            fullHeight={fullHeight}
            markVariant={markVariant}
            virtualized={virtualized}
            maxHeight={maxHeight}
            group={!!groupProperty}
            disabled={disabled}
            groupedOptions={groupedOptions}
            labelComponent={labelComponent}
            totalCount={totalCount}
            handleLoadMore={handleLoadMore}
            menuItemProps={menuItemProps}
            getOptionProperties={({ option }) => ({
              // disabled: disabled,
              selected: isSelectedValue(option[0]),
              option: option,
              onMouseDown: disabled ? null : handleSelect(option[0], isSelectedValue(option[0])),
              value: option[0],
              label: labelProperty ? option[1][labelProperty] || option[1] : option[1],
            })}
          />
        </Box>

        {Footer && (
          <Box className={menuClasses.footer}>
            <Footer onClose={onClose} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SelectOptions;
