import React from "react";
import { ApolloProvider, ApolloClient, HttpLink, ApolloLink, InMemoryCache } from "@apollo/client";
import { onError } from "apollo-link-error";
import { fetchAccessToken } from "contexts/Auth";
import { createUploadLink } from "apollo-upload-client";

const graphqlUrl = `${process.env.REACT_APP_GRAPHQL_ENDPOINT}/graphql`;

const uploadLink = createUploadLink({ uri: graphqlUrl });
const httpLink = new HttpLink({ uri: graphqlUrl });

const authMiddlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${fetchAccessToken()}`,
    },
  }));

  return forward(operation);
});

// const responseMiddlewareLink = new ApolloLink((operation, forward) => {
//   return forward(operation).map((response) => {
//     const context = operation.getContext();
//     const authHeader = context.response;
//     // localStorage.setItem("token", authHeader);
//     console.log(authHeader);
//     return response;
//   });
// });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authMiddlewareLink, uploadLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      GetTableAccessPayload: {
        // FIX THE ISSUE WITH DUPLICATED IDs IN RESPONSE (Reproduced in TabelView)
        keyFields: false,
      },
      TableViewColumnPayload: {
        // FIX THE ISSUE WITH DUPLICATED IDs IN RESPONSE (Reproduced in TabelView)
        keyFields: false,
      },
      TableColumn: {
        // FIX THE ISSUE WITH DUPLICATED IDs IN RESPONSE (Reproduced in TabelView)
        keyFields: false,
      },
      SublistTableAccessLink: {
        // FIX THE ISSUE WITH DUPLICATED IDs IN RESPONSE (Reproduced in TabelView)
        keyFields: false,
      },
      ConsolidatedTableAccessLink: {
        // FIX THE ISSUE WITH DUPLICATED IDs IN RESPONSE (Reproduced in TabelView)
        keyFields: false,
      },
      PrimaryTableAccessLink: {
        // FIX THE ISSUE WITH DUPLICATED IDs IN RESPONSE (Reproduced in TabelView)
        keyFields: false,
      },
      SecondaryTableAccessLink: {
        // FIX THE ISSUE WITH DUPLICATED IDs IN RESPONSE (Reproduced in TabelView)
        keyFields: false,
      },
      DateFormat: {
        // FIX THE ISSUE WITH DUPLICATED IDs IN RESPONSE (Reproduced in TabelView)
        keyFields: false,
      },
      TimeFormat: {
        // FIX THE ISSUE WITH DUPLICATED IDs IN RESPONSE (Reproduced in TabelView)
        keyFields: false,
      },
      GetItemByTableColumnPayload: {
        // FIX THE ISSUE WITH DUPLICATED IDs IN RESPONSE (Reproduced in TabelView)
        keyFields: false,
      },
    },
  }),
});

const Provider = ({ children }) => <ApolloProvider client={client}>{children}</ApolloProvider>;

export default Provider;
