import routes from "routes";
import { generatePath, useLocation, useParams, useNavigate, matchRoutes } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

const useCurrentRoutePath = () => {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);

  const path = matchedRoutes
    .map((route) => route.route.path)
    .join("/")
    .replaceAll("//", "/");

  return path === "/" ? path : path.replace(/\/$/, "");
};

export const useRouteMap = () => {
  const navigate = useNavigate();
  const params = useParams();
  const currentRoutePath = useCurrentRoutePath();
  // const currentNamespace = fetchNamespacePath(currentRoutePath);

  const replaceParams = (props = {}) => {
    const newPath = generatePath(currentRoutePath, { ...params, ...props });
    return navigate(newPath);
  };

  const replaceWorkspace = ({ companyId, projectId }) => {
    let path = currentRoutePath.replace(/:companyId/, companyId);

    if (projectId) {
      path = path.replace(/:projectId/, projectId);
    }

    path = path.replace(/:tableViewId/, ""); // TODO
    path = path.replace(/:id/, "");

    path = path === "/" ? path : path.replace(/\/$/, "");

    return navigate(generatePath(path, params));
  };

  const navigateToPath = (path, props = {}) => {
    return navigate(generatePath(path, { ...params, ...props }));
  };

  const navigateToEvent = (path, props) => (_event) => navigateToPath(path, props);

  const assignParamsProperty = (property, value) => {
    const regexp = new RegExp(`/:${property}$`);

    if (isEmpty(value) && currentRoutePath.match(regexp)) {
      navigate(generatePath(currentRoutePath.replace(`/:${property}`, ""), params));
      return;
    }

    if (currentRoutePath.match(regexp)) {
      replaceParams({ [property]: value });
      return;
    }

    if (!isEmpty(value)) {
      navigate(generatePath(`${currentRoutePath}/:${property}`, { ...params, [property]: value }));
    }
  };

  return {
    navigateToPath,
    navigateToEvent,
    replaceParams,
    replaceWorkspace,
    assignParamsProperty,
    currentRoutePath,
  };
};
