import React from "react";
import { TextField } from "@mui/material";
import FormControl from "components/FormControl";
import Box from "components/Box";
import Typography from "components/Typography";
import PLACE_HOLDERS from "constants/placeholders";
import clsx from "clsx";

const Input = ({
  name,
  label,
  error,
  readOnly = false,
  fullWidth,
  placeholder,
  hint,
  space = {},
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  tags: Tags,
  invalid,
  isTouched,
  focused,
  transparent,
  showError = true,
  style,
  value,
  innerRef,
  inputRef,
  labelIcon,
  size = "medium",
  variant,
  labelHeight,
  labelSpace,
  inputComponent = undefined,
  inputComponentProps = {},
  inputProps,
  onChange,
  anchorComponent,
  ...props
}) => {
  const StartAdornment =
    LeftIcon || Tags ? (
      <Box display="flex" alignItems="flex-start" className="leftIcon">
        {LeftIcon && <LeftIcon size={size} />}
        {Tags}
      </Box>
    ) : null;

  const EndAdornment = RightIcon ? (
    <Box display="flex" alignItems="center" className="rightIcon">
      <RightIcon size={size} />
    </Box>
  ) : null;

  const isText = props.type === "text";
  const textAreaFieldProps = isText ? { multiline: true, rows: 4 } : {};
  const inputPlaceholder = PLACE_HOLDERS[placeholder] || placeholder;

  return (
    <FormControl
      hint={hint}
      error={error}
      showError={showError}
      space={{ mb: 2, ...space }}
      fullWidth={fullWidth}
      style={style}
    >
      {label && (
        <Box space={{ mb: 1 }}>
          <Typography error={`${!!error}`} variant="inputLabel">
            {label}
          </Typography>
        </Box>
      )}

      {anchorComponent}

      <TextField
        ref={innerRef}
        inputRef={inputRef}
        name={name}
        focused={focused}
        invalid={invalid}
        isTouched={isTouched}
        InputProps={{
          ...props,
          onChange: onChange,
          variant,
          className: clsx(props.className, `MuiInputBase--size-${size}`),
          readOnly,
          startAdornment: StartAdornment,
          endAdornment: EndAdornment,
          inputComponent: inputComponent,
        }}
        inputProps={
          inputComponent ? { ...inputComponentProps, iconSize: size, ...inputProps } : inputProps
        }
        error={!!error}
        label={null}
        aria-describedby={`textField-${props.id || props.name}`}
        placeholder={inputPlaceholder}
        {...textAreaFieldProps}
        {...(value === undefined ? {} : { value: value || "" })}
      />
    </FormControl>
  );
};

export default Input;
