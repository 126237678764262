import { useMemo } from "react";
import { gql } from "@apollo/client";
import useQuery from "hooks/useQuery";

import { Timezone } from "./Timezone";

export const GET_TIMEZONES = gql`
  query GetTimezones {
    getTimezones {
      ...TimezoneFragment
    }
  }
  ${Timezone.fragment}
`;

export const useGetTimezones = () => {
  const { data: { getTimezones = [] } = {}, ...rest } = useQuery(GET_TIMEZONES);

  const timezones = useMemo(() => getTimezones.map((zone) => new Timezone(zone)), [getTimezones]);

  return { timezones, ...rest };
};
