const useDataSort = ({ sort, setSort }) => {
  const replaceSortByFieldName = (prevSort, sortFields) => {
    const sortFieldsKeys = sortFields.map((item) => item.field);
    const next = prevSort.sort((sortItem) => !sortFieldsKeys.includes(sortItem.field));

    return [...next, ...sortFields];
  };

  const removeSortByFieldName = (sortIds) => {
    setSort((prev) => prev.sort((sortItem) => !sortIds.includes(sortItem.field)));
  };

  const cleanSort = () => {
    setSort([]);
  };

  const applySort = sort.filter((item) => item.value);

  return {
    sort,
    applySort,
    setSort,
    cleanSort,
    replaceSortByFieldName,
    removeSortByFieldName,
  };
};

export default useDataSort;
