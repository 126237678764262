import * as Yup from "yup";
import pick from "lodash/pick";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import ERRORS from "constants/errors";
import { isNone } from "utils/detect";
import { FORMATS } from "utils/time";

export const yupValuesResolver = (entity, schema) =>
  !!entity ? pick(entity, Object.keys(schema.fields)) : {};

export const YupFile = Yup.mixed().test(
  "size",
  "Can't read file size",
  (value) => value?.size !== null
);

export const atLeastOne = (
  yup,
  { message = "Must have at least one added value", filter = null } = {}
) =>
  yup.test({
    message,
    test: (arr) => {
      const array = arr || [];
      return (filter ? array.filter(filter) : array).length !== 0;
    },
  });

export const requriedForValues = (yup, property, values = []) =>
  Yup.mixed().when(property, (value) => {
    return values.includes(value) ? yup.nullable().required(ERRORS.required) : yup.nullable();
  });

export const noRequriedForValues = (yup, property, values = []) =>
  Yup.mixed().when(property, (value) => {
    return values.includes(value) ? yup.nullable() : yup.nullable().required(ERRORS.required);
  });

export const requiredIfPresent = (yup, property) =>
  Yup.mixed().when(property, (property) => {
    return isNone(property) ? yup.nullable() : yup.nullable().required(ERRORS.required);
  });

export const noRequiredIfPresent = (yup, property) =>
  Yup.mixed().when(property, (property) => {
    return isNone(property) ? yup.nullable().required(ERRORS.required) : yup.nullable();
  });

export const requiredIf = (yup, property) =>
  Yup.mixed().when(property, (property) => {
    return property ? yup.nullable().required(ERRORS.required) : yup.nullable();
  });

export const noRequriedIf = (yup, property) =>
  Yup.mixed().when(property, (property) => {
    return property ? yup.nullable() : yup.nullable().required(ERRORS.required);
  });

export const YupDate = Yup.string().test({
  message: ERRORS.date,
  test: (value) => {
    return isEmpty(value) ? true : new Date(value) !== "Invalid Date";
  },
});

export const YupDateTime = Yup.string().test({
  message: ERRORS.dateTime,
  test: (value) => {
    return isEmpty(value) ? true : new Date(value) !== "Invalid Date Time";
  },
});

export const YupNumber = Yup.number().transform((value) =>
  isNaN(value) || value === null ? null : value
);

export const minNumberValue = (yup, { label, maxName }) =>
  yup.label(label).test({
    name: `validator-minNumberValue-${label}-${maxName}`,
    test: function (value) {
      const maxValue = this.parent[maxName];

      if (!maxValue) return true;

      return value < maxValue
        ? true
        : this.createError({
            message: `${label} must be less than ${maxValue}`,
            path: this.path,
          });
    },
  });

export const maxNumberValue = (yup, { label, minName }) =>
  yup.label(label).test({
    name: `validator-maxNumberValue-${label}-${minName}`,
    test: function (value) {
      const minValue = this.parent[minName];

      if (!minValue) return true;

      return value > minValue
        ? true
        : this.createError({
            message: `${label} must be greater than ${minValue}`,
            path: this.path,
          });
    },
  });

export const minTimeValue = (yup, { label, maxName, format = "date", formatFunc }) =>
  yup.label(label).test({
    name: "validator-minTimeValue",
    test: function (value) {
      const formatPattern = formatFunc ? formatFunc(this.parent) : FORMATS[format];
      const maxValue = this.parent[maxName];

      if (isEmpty(maxValue)) return true;

      return moment(parseInt(maxValue)).isAfter(moment(parseInt(value)))
        ? true
        : this.createError({
            message: `${label} must be less than ${moment(parseInt(maxValue)).format(
              formatPattern
            )}`,
            path: this.path,
          });
    },
  });

export const maxTimeValue = (yup, { label, minName, format = "date", formatFunc }) =>
  yup.label(label).test({
    name: "validator-maxTimeValue",
    test: function (value) {
      const formatPattern = formatFunc ? formatFunc(this.parent) : FORMATS[format];
      const minValue = this.parent[minName];

      if (isEmpty(minValue)) return true;

      return moment(parseInt(value)).isAfter(moment(parseInt(minValue)))
        ? true
        : this.createError({
            message: `${label} must be greater than ${moment(parseInt(minValue)).format(
              formatPattern
            )}`,
            path: this.path,
          });
    },
  });
