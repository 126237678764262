import React from "react";
import { List as MuiList } from "@mui/material";
import Typography from "components/Typography";
import withClasses from "components/withClasses";

import ListItemComponent from "./ListItem";

import "styles/App/List.scss";

const ListComponent = ({ children, skeleton, variantProps, innerRef, ...rest }) => {
  return (
    <MuiList ref={innerRef} {...rest} classes={{ root: "AppList-root" }}>
      {skeleton
        ? Array.from(Array(skeleton)).map((_, index) => (
            <ListItem key={`list-item-${index}-skeleton`} loading />
          ))
        : children}
    </MuiList>
  );
};

export const List = withClasses(ListComponent, "MuiList", {});

export const ListTitle = (props) => {
  return <Typography {...props} className="AppListTitle-root" />;
};

export const ListItem = ListItemComponent;
