import "styles/App/AuthLayout.scss";

export const useLayoutStyles = () => ({
  root: "AppAuthLayout-root",
  wrapper: "AppAuthLayout-root__wrapper",
  contentContainer: "AppAuthLayout-root__container",
  content: "AppAuthLayout-root__content",
  logo: "AppAuthLayout-root__logo",
  header: "AppAuthLayout-root__header",
});

export const usePageStyles = () => ({
  root: "",
  container: "",
  bgImage: "AppAuthPage-root__background-image"
});
