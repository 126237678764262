export const TABLE_MODEL_TYPE = "TABLE";
export const TABLE_COLUMN_MODEL_TYPE = "TABLE_COLUMN";
export const TABLE_COLUMN_DEFINITION_MODEL_TYPE = "TABLE_COLUMN_DEFINITION";
export const CHANNEL_MODEL_TYPE = "CHANNEL";
export const CHANNEL_OPERATION_MODEL_TYPE = "CHANNEL_OPERATION";
export const CHANNEL_DEFINITION_MODEL_TYPE = "CHANNEL_DEFINITION";
export const FILE_MODEL_TYPE = "FILE";
export const FILE_COLUMN_MODEL_TYPE = "FILE_COLUMN";
export const COMPANY_MODEL_TYPE = "COMPANY";
export const PROJECT_MODEL_TYPE = "PROJECT";
export const LIST_ITEM_MODEL_TYPE = "LIST_ITEM";
export const TABLE_VIEW_MODEL_TYPE = "TABLE_VIEW";
