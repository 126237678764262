export const inputStep = (precision) => {
  const value = parseInt(precision);

  return {
    0: "1",
    1: "0.1",
    2: "0.01",
  }[value];
};

export const stopPropagation = (event) => event.stopPropagation();
