import React, { useState } from "react";
import { Drawer as MuiDrawer } from "@mui/material";
import { useDrawerStyles } from "theme/components/Drawer";
import withClasses from "components/withClasses";
import clsx from "clsx";
import { useDelayOpen } from "components/Menu";
import { useOnEscEffect } from "utils/effects";

const DrawerContext = React.createContext(null);

const DrawerComponent = withClasses(
  ({ innerRef, variantProps, ...props }) => <MuiDrawer {...props} />,
  "MuiDrawer",
  {
    size: null,
  }
);

export const SideDrawer = ({ open, children, ...props }) => {
  const delayOpen = useDelayOpen(open);

  return (
    <MuiDrawer
      className={clsx({ opened: open })}
      variant="persistent"
      anchor="right"
      open={open}
      BackdropProps={{
        invisible: true,
        className: "hidden",
      }}
      {...props}
    >
      {(delayOpen || open) && children}
    </MuiDrawer>
  );
};

const SKIP_TAGS = ["INPUT"];

const transition = (_node, _duration) => {
  // node.style.webkitTransition = theme.transitions.create(["-webkit-transform", "width"], {
  //   easing: theme.transitions.easing.easeOut,
  //   duration: duration,
  // });
  // node.style.transition = theme.transitions.create(["-webkit-transform", "width"], {
  //   easing: theme.transitions.easing.easeOut,
  //   duration: duration,
  // });
};

const SlideProps = {
  onEntering: (node) => transition(node, "250ms"),
};

const NoTransitionSlideProsp = {
  onEntering: (node) => transition(node, "0ms"),
  onExiting: (node) => transition(node, "0ms"),
  onExit: (node) => transition(node, "0ms"),
};

const Drawer = ({
  innerRef,
  height,
  children,
  size,
  open,
  fullHeight,
  anchor = "right",
  PaperProps = {},
  transition = true,
  subDrawer = false,
  disableBackdropClick = true,
  disableEscapeKeyDown = false,
  hiddenBackdrop = false,
  onClose,
  position = "persistent",
  delayOpening = true,
  customFullScreenState,

  ...props
}) => {
  const classes = useDrawerStyles({ size });
  const fullScreenState = useState(false);
  const [fullScreen, setFullScreen] = customFullScreenState || fullScreenState;
  const [visibleSummary, setSummary] = useState(true);
  const delayOpen = useDelayOpen(open, delayOpening);

  const toggleFullScreen = () => setFullScreen((prev) => !prev);
  const toggleSummary = () => setSummary((prev) => !prev);

  const handleClose = (event, reason) => {
    if (!open) return;
    if (disableBackdropClick && reason === "backdropClick") return false;
    if (disableEscapeKeyDown && reason === "escapeKeyDown") return false;
    if (typeof onClose === "function") onClose();
  };

  useOnEscEffect(innerRef, (event) => {
    return SKIP_TAGS.includes(event.target?.tagName) ? null : handleClose(event, "escapeKeyDown");
  });

  const context = {
    size,
    fullScreen,
    toggleFullScreen,
    visibleSummary,
    toggleSummary,
    onClose,
  };

  return (
    <DrawerComponent
      ref={innerRef}
      {...props}
      open={open}
      classes={classes}
      variant={position}
      PaperProps={{
        ...PaperProps,
        style: { ...PaperProps.style, height },
        className: clsx({ subDrawer, fullHeight }),
      }}
      SlideProps={transition ? SlideProps : NoTransitionSlideProsp}
      BackdropProps={{
        invisible: true,
        className: hiddenBackdrop ? classes.hiddenBackdrop : null,
      }}
      anchor={anchor}
      onClose={handleClose}
    >
      <DrawerContext.Provider value={context}>
        {(delayOpen || open) && children}
      </DrawerContext.Provider>
    </DrawerComponent>
  );
};

export const useDrawer = () => React.useContext(DrawerContext);

export default Drawer;
