import React, { useState } from "react";
import Box from "components/Box";
import { List } from "components/List";
import { Drawer } from "@mui/material";
import IconButton from "components/IconButton";
import Typography from "components/Typography";
import clsx from "clsx";
import { ArrowLeftIcon2, ChevronsLeftIcon } from "icons";
import { useSideBarStyles } from "theme/components/DashboardLayout";
import { useSideNavigationListStyles } from "theme/components/SideNavigation";
import { useLayout } from "contexts/Layout";
import { useLocation } from "react-router-dom";

import AccountMenu from "../AccountMenu";
import WorkspaceMenu from "../WorkspaceMenu";
import MainSection from "./MainSection";
import SettingsSection from "./SettingsSection";

const SideNavBar = () => {
  const {
    openedSidebar,
    toggleOpenedSidebar,
    fixedOpenedSidebar,
    toggleFixedOpenedSidebar,
    setHoveredSidebar,
  } = useLayout();

  const closedSidebar = !openedSidebar;
  const location = useLocation();
  const classes = useSideBarStyles();
  const itemClasses = useSideNavigationListStyles();

  const [navigationSection, setNavigationSection] = useState(
    location.pathname.match(/settings/) ? "SETTINGS" : "MAIN"
  );

  const toggleSection = (mode) => () => setNavigationSection(mode);

  return (
    <div>
      <div className={clsx(classes.stub, { opened: !closedSidebar })}></div>

      <Drawer
        onMouseEnter={() => setHoveredSidebar(true)}
        onMouseLeave={() => setHoveredSidebar(false)}
        anchor="left"
        open={true}
        variant="permanent"
        classes={classes}
        className={clsx({
          closed: closedSidebar,
          hidden: fixedOpenedSidebar ? false : closedSidebar,
        })}
        PaperProps={{ className: clsx({ closed: closedSidebar }) }}
      >
        <Box
          className={classes.topMenuWrap}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {navigationSection === "MAIN" && (
            <Box className={clsx(classes.profileButton)}>
              <WorkspaceMenu
                size="small"
                variant="ghost"
                onOpen={toggleFixedOpenedSidebar}
                onClose={toggleFixedOpenedSidebar}
              />
            </Box>
          )}

          {navigationSection === "SETTINGS" && (
            <Box
              space={{ pl: 1 }}
              className={clsx(classes.profileButton, classes.avatarTitle)}
              display="flex"
              alignItems="center"
            >
              <IconButton
                space={{ mr: 0.5 }}
                icon={ArrowLeftIcon2}
                size="small"
                iconSize="large"
                onClick={toggleSection("MAIN")}
              />

              <Typography color="dark">Settings</Typography>
            </Box>
          )}

          <Box display="flex" alignItems="center">
            {navigationSection !== "SETTINGS" && (
              <AccountMenu
                space={{ mr: 1 }}
                onOpen={toggleFixedOpenedSidebar}
                onClose={toggleFixedOpenedSidebar}
                className={itemClasses.cornerIcon}
              />
            )}

            <IconButton
              icon={ChevronsLeftIcon}
              className={clsx(itemClasses.toggleSidebarButton, { collapsed: !openedSidebar })}
              size="small"
              iconSize="large"
              onClick={toggleOpenedSidebar}
            />
          </Box>
        </Box>

        <List>
          {navigationSection === "MAIN" && <MainSection toggleSection={toggleSection} />}
          {navigationSection === "SETTINGS" && <SettingsSection toggleSection={toggleSection} />}
        </List>
      </Drawer>
    </div>
  );
};

export default SideNavBar;
