import "styles/App/Content.scss";

export const contentStyles = {
  sticky: "AppContent-sticky",
  container: "AppContent-container",
  maxHeight: "AppContent-maxHeight",
  title: "AppContent-title",
  fullHeight: "AppContent-fullHeight",
  fullHeightScroll: "AppContent-fullHeightScroll",
  fullWidth: "AppContent-fullWidth",
  sourceCards: "AppContent-sourceCards",
  pageContent: "AppContent-pageContent",
  pageTable: "AppContent-pageTable",
};

export const useContentStyles = () => contentStyles;
