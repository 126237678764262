import { gql } from "@apollo/client";
import { delegate, memoize, serialize } from "utils/entities";

import { TABLE_COLUMN_MODEL_TYPE } from "models/types";
import { FieldType } from "models/abstract/FieldType";
import { FieldTypeConfig, FIELD_TYPE_CONFIG } from "models/abstract/FieldTypeConfig";
import { FieldFull } from "models/Field/FieldFull";
import { FormField } from "models/abstract/FormField";

export class TableColumn {
  constructor(origin, props = {}) {
    const { lastModifiedDate } = props;

    this.origin = { lastModifiedDate, ...origin };
    this.cache = {};
    this.memoize = memoize(this.cache);

    delegate(this, origin);

    serialize(this, { field: FieldFull });
  }

  get modelType() {
    return TABLE_COLUMN_MODEL_TYPE;
  }

  get fieldId() {
    return this.field.id;
  }

  get subtype() {
    return this._configEntity.subtype;
  }

  get fieldType() {
    return this.memoize(
      "fieldType",
      () => new FieldType({ type: this.type, subtype: this.subtype })
    );
  }

  get config() {
    return this._configEntity.config;
  }

  get formField() {
    return new FormField({
      id: this.id,
      fieldId: this.fieldId,
      name: this.id,
      label: this.name,
      disabled: false,
      icon: null,
      type: this.type,
      subtype: this.subtype,
      editable: !this.blank,
      required: this.mandatory,
      config: this.config,
      placeholder: this.placeholder,
    });
  }

  // PRIVATE

  get _configEntity() {
    return this.memoize("_configEntity", () => new FieldTypeConfig({ ...this.origin }));
  }

  get _fieldConfigEntity() {
    return this.memoize("_fieldConfigEntity", () => new FieldTypeConfig({ ...this.origin.field }));
  }
}

TableColumn.fragment = gql`
  fragment TableColumnFragment on TableColumn {
    id
    createdDate
    inUse
    uid
    name
    type
    unique
    mandatory
    search
    blank
    placeholder
    ${FIELD_TYPE_CONFIG}
    field {
      ...FieldFullFragment
    }
  }

  ${FieldFull.fragment}
`;
