import isEmpty from "lodash/isEmpty";

const toTitleCase = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const toString = (errors) => {
  if (typeof errors !== "object" || errors === null) {
    return errors;
  }

  const parsedErrors = Object.keys(errors).reduce((result, errorKey) => {
    return [`${toTitleCase(errorKey)} ${errors[errorKey]}`, ...result];
  }, []);

  return parsedErrors.join(", ");
};

const parseErrors = (errors) => {
  if (!errors) {
    return {
      submit: { message: "Something went wrong, please make sure you entered correct data" },
    };
  }

  const errorsObject = errors.reduce((result, error) => {
    if (!error.path && error.message) {
      result.submit = "Something went wrong, please make sure you entered correct data";
      return result;
    }

    if (error.path?.length === 0) {
      result.submit = "Something went wrong, please make sure you entered correct data";
    } else {
      const field =
        error.path?.length <= 2 ? "submit" : error.path.splice(2, error.path.length + 1).join(".");

      result[field] = error.message;
    }

    if (!result.submit) {
      const field = Object.keys(result)[0];
      if (field) result.submit = result[field];
    }

    return result;
  }, {});

  if (isEmpty(errorsObject)) {
    errorsObject.submit = "Something went wrong, please make sure you entered correct data";
  }

  return errorsObject;
};

export const fetchRowIndex = (errorKey) => {
  let rowIndex = errorKey.match(/\[\d+\]/);

  if (rowIndex === null || rowIndex === undefined) return null;

  rowIndex = rowIndex[0]?.match(/\d+/);

  if (rowIndex === null || rowIndex === undefined) return;

  return rowIndex[0];
};

export default parseErrors;
