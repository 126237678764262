import caller from "./caller";
import keyBy from "lodash/keyBy";
import max from "lodash/max";
import maxBy from "lodash/maxBy";
import isEmpty from "lodash/isEmpty";
import { isReactComponent } from "utils/detect";
import {
  CELL_PADDING_OFFSET,
  HEADER_ICONS_WIDTH,
  ROOT_CELL_PADDING,
} from "theme/components/TablePro";

const getTextWidth = (text) => {
  const font = "bold 11pt arial";
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  const { width } = context.measureText(text);

  return width;
};

export const getFieldTextWidth = (text) => {
  if (!text || isReactComponent(text)) return 0;

  return Math.round(getTextWidth(text));
};

const fetchEntityFieldWidth = (field) => (entity) => {
  const fieldText = callerWidthField(entity, field);

  const rootCellPadding = field.root ? ROOT_CELL_PADDING : 0;

  const width = getFieldTextWidth(fieldText) + CELL_PADDING_OFFSET + rootCellPadding;

  return width;
};

export const calculateWidth = (fields, entities) => {
  return fields.reduce((result, field) => {
    if (field.width) {
      result[field.id] = field.width;
      return result;
    }

    const labelWidth = getFieldTextWidth(field.label) + 10 + HEADER_ICONS_WIDTH;

    const entitiesWidth = entities.slice(0, 200).map(fetchEntityFieldWidth(field));

    const fieldWidth = max([...entitiesWidth, labelWidth]);

    result[field.id] = fieldWidth;

    return result;
  }, {});
};

const optionName = (option, labelProperty) =>
  option[labelProperty] || option.label || option.name || "";

const fetchOptionText = (option, labelProperty) => {
  if (isEmpty(option)) return "";

  if (option instanceof Array) {
    return option[1] instanceof Object ? optionName(option[1], labelProperty) : option[1];
  }

  return optionName(option, labelProperty);
};

export const findLongestOption = (options, labelProperty) => {
  const maxOption = maxBy(options, (option) => {
    return fetchOptionText(option, labelProperty).length;
  });

  return fetchOptionText(maxOption);
};

const callerWidthField = (entity, field) => {
  const options = entity[`${field.id}Options`];

  if (options) return findLongestOption(options);

  return field.widthValue
    ? field.widthValue({ entity, tableColumn: field })
    : callerField(entity, field);
};

export const callerField = (entity, field) =>
  field.value ? field.value({ entity, tableColumn: field }) : caller(entity, field.id);

export default caller;

export const mergeFields = (mainCollection, secondCollection) => {
  const mainFields = keyBy(mainCollection, "id");
  const secondFields = keyBy(secondCollection, "id");

  return mainCollection.reduce((result, field) => {
    const newField = {
      ...mainFields[field.id],
      ...secondFields[field.id],
    };

    return [...result, newField];
  }, []);
};

export const mergeRefs = (refs) => {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref !== null) {
        ref.current = value;
      }
    });
  };
};
